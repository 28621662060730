import AutocompleteField from "@Molecule/AutocompleteField";
import InputField, { InputTitle } from "@Molecule/InputField";
import Styles from "./style.module.scss";
import InputDateField from "@Molecule/InputDateField";
import BMSelector from "@Organism/BusinessSupport/Candidates/CandidateDetails/BMSelector";
import ContractTypeSelector from "@Organism/BusinessSupport/Candidates/CandidateDetails/ContractTypeSelector";
import AutocompleteV2 from "@Atom/AutocompleteV2";
import { useEffect, useMemo, useState } from "react";

export default function FirstStep({
  values,
  setValues,
  clientList,
  orderList,
  positionList,
  sync,
  selectedContractId,
  setSelectedContractId,
  contractList,
  selectedBM,
  setSelectedBM,
  selectedContractType,
  setSelectedContractType,
  selectedClient,
  setSelectedClient,
  selectedOrder,
  setSelectedOrder,
  selectedPosition,
  setSelectedPosition
}) {
  const handleChangeValues = (newVal, code) => {
    setValues(
      values?.map((obj) => {
        if (obj?.code === code) {
          return {
            ...obj,
            value: newVal,
          };
        }
        return obj;
      })
    );
  };
  // const handleChangeContractId = (newVal) => {
  //   const data = contractList?.contractTemplates?.find(
  //     (el) => el?.name === newVal
  //   );

  //   // console.log(contractList?.contractTemplates);

  //   setSelectedContractId(data);
  // };

  const [valueSearch, setValueSearch] = useState(selectedContractId?.name || "");

  useEffect(() => {
    if (valueSearch) {
      const data = contractList?.contractTemplates?.find(
        (obj) => obj?.name?.toLowerCase() === valueSearch?.toLowerCase()
        // (obj) => obj?.name?.toLowerCase()?.includes(valueSearch?.toLowerCase())
      );
      setSelectedContractId(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedContractId, valueSearch]);

  const optionClient = useMemo(() => {
      const option =
          clientList
              ?.filter((f) => f.name.toLowerCase().includes(selectedClient?.name?.toLowerCase() || ''))
              ?.map((elemC) => {
                  return {
                      name: elemC?.name,
                  };
              }) || [];
      return option;
  }, [selectedClient, clientList]);
  
  const optionOrder = useMemo(() => {
      const option =
          orderList
              ?.filter((f) =>
                  f.clientContracts.docNumber
                      ?.toLowerCase()
                      .includes(selectedOrder?.clientContracts?.docNumber?.toLowerCase() || '')
              )
              ?.map((elemC) => {
                  return {
                      name: elemC?.clientContracts?.docNumber,
                      label: elemC?.clientContracts?.schemeID?.toString(),
                  };
              }) || [];
      return option;
  }, [selectedOrder, orderList]);
  
  const optionPosition = useMemo(() => {
      const option =
          positionList
              ?.filter((f) => f.name.toLowerCase().includes(selectedPosition?.name?.toLowerCase() || ''))
              ?.map((elemC) => {
                  return {
                      name: elemC?.name,
                  };
              }) || [];
      return option;
  }, [selectedPosition, positionList]);

  // useEffect(() => {
  //   console.log('valueSearch', valueSearch)
  //   console.log('valueSearch', selectedContractId)
  // }, [valueSearch, selectedContractId])

  // useEffect(() => {
  //   console.log('selectedClient', selectedClient)
  //   console.log('filter', clientList?.filter((f) => f.name.includes('PT')))
  // }, [selectedClient])

  return (
      <div className={Styles.container}>
          <div>
              <BMSelector selectedBM={selectedBM} setSelectedBM={setSelectedBM} data={contractList?.businessManager} />
          </div>
          <div>
              <ContractTypeSelector
                  selectedContractType={selectedContractType}
                  setSelectedContractType={setSelectedContractType}
              />
          </div>
          <div>
              <InputTitle title={'Template Kontrak'} />
              <AutocompleteV2
                  value={valueSearch}
                  setValue={setValueSearch}
                  placeholder={'Pilih template'}
                  options={
                      contractList?.contractTemplates
                          ?.filter((f) => f.name.includes(selectedContractType))
                          .map((obj) => obj?.name)
                          ?.map((obj) => {
                              return {
                                  name: obj,
                              };
                          }) || []
                  }
              />
          </div>

          {values && (
              <>
                  <div>
                      <InputTitle title={'Klien'} />
                      <AutocompleteV2
                          value={selectedClient?.name || ''}
                          setValue={(newValue) => {
                              const findValue = clientList?.find((f) => f.name === newValue);
                              if (findValue) {
                                  setSelectedClient(findValue);
                              } else {
                                  setSelectedClient({
                                      name: newValue,
                                  });
                              }
                          }}
                          placeholder={'Pilih Klien'}
                          options={optionClient}
                      />
                  </div>
                  <div>
                      <InputTitle title={'FLB'} />
                      <AutocompleteV2
                          value={selectedOrder?.clientContracts?.docNumber || ''}
                          onClickOption={(newValue)=>{
                            const findValue = orderList?.find((f) => f?.clientContracts?.schemeID?.toString() === newValue?.label);
                              if (findValue) {
                                  setSelectedOrder(findValue);
                              } else {
                                  setSelectedOrder({
                                      'clientContracts.docNumber': newValue,
                                  });
                              }
                          }}
                          placeholder={'Pilih FLB'}
                          options={optionOrder}
                      />
                  </div>
                  <div>
                      <InputTitle title={'Posisi'} />
                      <AutocompleteV2
                          value={selectedPosition?.name || ''}
                          setValue={(newValue) => {
                              const findValue = positionList?.find((f) => f.name === newValue);
                              if (findValue) {
                                  setSelectedPosition(findValue);
                                  handleChangeValues(findValue.name, '{CANDIDATE_POSITION}');
                                //   handleChangeValues(findValue.salary, '{SALARY}')
                                //   setSelectedContractType(findValue?.contractType);
                              } else {
                                  setSelectedPosition({
                                      name: newValue,
                                  });
                              }
                          }}
                          placeholder={'Pilih Posisi'}
                          options={optionPosition}
                      />
                  </div>
              </>
          )}

          {values
              ?.filter((obj) => obj?.code !== '{CONTRACT_STARTDATE}' && obj?.code !== '{CONTRACT_ENDDATE}')
              // eslint-disable-next-line array-callback-return
              ?.map((v, i) => {
                  if (v?.code?.toLowerCase()?.includes('dob')) {
                      return (
                          <InputDateField
                              key={i}
                              title={v?.label}
                              value={v?.value}
                              setValue={(newVal) => handleChangeValues(newVal, v?.code)}
                          />
                      );
                  } else if (v?.code?.toLowerCase()?.includes('pks')) {
                      return (
                          <AutocompleteField
                              key={i}
                              title={v?.label}
                              value={v?.value}
                              setValue={(newVal) => handleChangeValues(newVal, v?.code)}
                              searchOptions={sync?.PKSlist?.map((obj) => obj?.value)}
                          />
                      );
                  } else if (v?.code?.toLowerCase()?.includes('position')) {
                      // return (
                      //     <InputField
                      //         key={i}
                      //         title={v?.label}
                      //         value={v?.value?.toUpperCase()}
                      //         onChange={(e) => handleChangeValues(e?.target?.value?.toUpperCase(), v?.code)}
                      //         disabled
                      //     />
                      // );
                  } else if (!v?.code?.toLowerCase()?.includes('salary')) {
                      return (
                          <InputField
                              key={i}
                              title={v?.label}
                              value={v?.value}
                              onChange={(e) => handleChangeValues(e?.target?.value, v?.code)}
                              isCurrency={v?.code?.toLowerCase()?.includes('salary') ? true : false}
                          />
                      );
                  } else {
                      return <div key={i} />;
                  }
              })}
      </div>
  );
}

//<div className={Styles.container}>
//   {
//     values?.map((v, i) => (
//       <InputField
//         title={v?.label}
//         value={v?.value}
//         onChange={(e) => handleChangeValues(e?.target?.value, v?.code)}
//       />
//     ))
//   }
//   <AutocompleteField
//     title={'Vendor'}
//     value={form?.vendor}
//     setValue={(newVal) => handleChangeForm(newVal, 'vendor')}
//     searchOptions={[
//       "vendor 1",
//       "vendor 2",
//     ]}
//   />
//   <InputField
//     title={'NIK/Sales Code'}
//     placeholder={'NIK/Sales Code'}
//     value={form?.salesCode}
//     onChange={(e) => handleChangeForm(e?.target?.value, 'salesCode')}
//   />
//   <AutocompleteField
//     title={'Client'}
//     value={form?.client}
//     setValue={(newVal) => handleChangeForm(newVal, 'client')}
//     searchOptions={[
//       "client 1",
//       "client 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'PKS'}
//     value={form?.pks}
//     setValue={(newVal) => handleChangeForm(newVal, 'pks')}
//     searchOptions={[
//       "pks 1",
//       "pks 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Posisi'}
//     value={form?.position}
//     setValue={(newVal) => handleChangeForm(newVal, 'position')}
//     searchOptions={[
//       "position 1",
//       "position 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Jabatan'}
//     value={form?.jobTitle}
//     setValue={(newVal) => handleChangeForm(newVal, 'jobTitle')}
//     searchOptions={[
//       "job 1",
//       "job 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'UMK'}
//     value={form?.umk}
//     setValue={(newVal) => handleChangeForm(newVal, 'umk')}
//     searchOptions={[
//       "umk 1",
//       "umk 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Lokasi'}
//     value={form?.location}
//     setValue={(newVal) => handleChangeForm(newVal, 'location')}
//     searchOptions={[
//       "location 1",
//       "location 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Template Kontrak'}
//     value={form?.template}
//     setValue={(newVal) => handleChangeForm(newVal, 'template')}
//     searchOptions={[
//       "template 1",
//       "template 2",
//     ]}
//   />
//   <InputField
//     title={'Alamat penempatan'}
//     value={form?.placement}
//     onChange={(e) => handleChangeForm(e?.target?.value, 'placement')}
//     placeholder={'Alamat penempatan'}
//   />
// </div>
