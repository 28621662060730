import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AddLocationModalV2 } from "../Fourth/LocationSection/AddLocationModal";
import SearchBar from "@Atom/SearchBar";
import UseOutsideClick from "@Hooks/useOutsideClick";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import { Avatar } from "@mui/material";
import { makeRandomString } from "@Helpers/makeRandomString";

export default function DeploymentStep({
  delegationsForm = [],
  setDelegationsForm = () => {},
  cities = [],
  defaultDeployments = [],
  headerHeight,
  alertFound,
}) {
  const [showAdd, setShowAdd] = useState(false);
  const [search, setSearch] = useState("");

  const [activeCityID, setActiveCityID] = useState("");

  useEffect(() => {
    if (!activeCityID && delegationsForm?.length) {
      setActiveCityID(delegationsForm[0]?.cityCode);
    }
  }, [activeCityID, delegationsForm]);

  const [bmSearch, setBmSearch] = useState("");
  const [amSearch, setAmSearch] = useState("");
  const [aoSearch, setAoSearch] = useState("");
  const [roSearch, setRoSearch] = useState("");

  const [showBm, setShowBm] = useState(false);
  const [showAm, setShowAm] = useState(false);
  const [showAo, setShowAo] = useState(false);
  const [showRo, setShowRo] = useState(false);

  const bmRef = useRef();
  const amRef = useRef();
  const aoRef = useRef();
  const roRef = useRef();

  const bmRef1 = useRef();
  const amRef1 = useRef();
  const aoRef1 = useRef();
  const roRef1 = useRef();

  const testRef = useRef();

  const bmOutsideClick = UseOutsideClick(bmRef);
  const amOutsideClick = UseOutsideClick(amRef);
  const aoOutsideClick = UseOutsideClick(aoRef);
  const roOutsideClick = UseOutsideClick(roRef);

  useEffect(() => {
    if (bmOutsideClick) {
      setBmSearch("");
      setShowBm(false);
    }
  }, [bmOutsideClick]);
  useEffect(() => {
    if (amOutsideClick) {
      setAmSearch("");
      setShowAm(false);
    }
  }, [amOutsideClick]);
  useEffect(() => {
    if (aoOutsideClick) {
      setAoSearch("");
      setShowAo(false);
    }
  }, [aoOutsideClick]);
  useEffect(() => {
    if (roOutsideClick) {
      setRoSearch("");
      setShowRo(false);
    }
  }, [roOutsideClick]);

  const searchTemplate = useMemo(() => {
    return [
      [bmSearch, setBmSearch, bmRef, showBm, setShowBm, bmRef1],
      [amSearch, setAmSearch, amRef, showAm, setShowAm, amRef1],
      [aoSearch, setAoSearch, aoRef, showAo, setShowAo, aoRef1],
      [roSearch, setRoSearch, roRef, showRo, setShowRo, roRef1],
    ];
  }, [amSearch, aoSearch, bmSearch, roSearch, showAm, showAo, showBm, showRo]);

  const onSelect = useCallback(
    (user, role) => {
      // if (!user?.isPicked) {
      const activeData = delegationsForm?.find(
        (obj) => obj?.cityCode === activeCityID
      );
      const newData = {
        ...activeData,
        deployments: activeData?.deployments?.map((obj) => {
          if (obj?.role === role) {
            return {
              ...obj,
              delegations: obj?.delegations?.map((u) => {
                if (u?.id === user?.id) {
                  return {
                    ...u,
                    isPicked:
                      role === "RECRUITMENT OFFICER" && user?.delegationID
                        ? u?.isPicked
                        : !u?.isPicked,
                  };
                } else {
                  return {
                    ...u,
                    isPicked: role === "BUSINESS MANAGER" ? false : u?.isPicked,
                  };
                }
              }),
            };
          } else {
            return obj;
          }
        }),
      };
      // }
      // else {

      // }
      setDelegationsForm(
        delegationsForm?.map((obj) =>
          obj?.cityCode === activeCityID ? newData : obj
        )
      );
      if (role === "BUSINESS MANAGER") {
        setShowBm(false);
      }
    },
    [activeCityID, delegationsForm, setDelegationsForm]
  );

  const onDelete = (cityCode) => {
    setDelegationsForm(
      delegationsForm?.filter((obj) => obj?.cityCode !== cityCode)
    );
    setActiveCityID("");
  };

  return (
    <div
      ref={testRef}
      className={Styles.container}
      style={{
        maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)`,
      }}
    >
      <div
        style={{
          maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)`,
        }}
        className={Styles.index}
      >
        <div className={Styles.iHeader}>
          <div className={Styles.iHLogo}>
            <Icon icon={"contracted"} size={16} color={"#1571DE"} />
          </div>
          <span>Daftar Lokasi</span>
        </div>
        {!!delegationsForm?.length ? (
          <div className={Styles.iContent}>
            <div className={Styles.icHeader}>
              <button onClick={() => setShowAdd(true)}>
                <Icon icon="add-circle" size={20} />
                <span>Tambah Lokasi</span>
              </button>
              <div className={Styles.searchWrapper}>
                <SearchBar
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                  placeholder={"Cari"}
                />
              </div>
            </div>

            <div className={Styles.iList}>
              {delegationsForm
                ?.filter((obj) =>
                  obj?.cityName?.toUpperCase()?.includes(search?.toUpperCase())
                )
                ?.map((d, dI) => (
                  <div
                    key={dI}
                    onClick={() => setActiveCityID(d?.cityCode)}
                    className={`${Styles.each} ${
                      Styles[activeCityID === d?.cityCode ? "active" : ""]
                    }`}
                  >
                    <Icon icon={"building"} size={20} />
                    <span>{d?.cityName}</span>
                    <Icon icon={"arrow-right"} size={20} />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className={Styles.iEmpty}>
            <img src={Images.HIGH_BUILDING} alt="" />
            <span>Belum ada data lokasi ditambahkan</span>
          </div>
        )}
      </div>
      <div
        style={{
          maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)`,
        }}
        className={Styles.main}
      >
        {delegationsForm?.length ? (
          delegationsForm?.map((d, dI) => (
            <div
              key={dI}
              className={`${Styles.locationData} ${
                Styles[activeCityID === d?.cityCode ? "visible" : "hidden"]
              }`}
            >
              <div className={Styles.ldHeader}>
                <h3>{d?.cityName}</h3>
                {!d?.preventDelete && (
                  <button onClick={() => onDelete(d?.cityCode)}>
                    <Icon icon={"trash"} size={20} />
                  </button>
                )}
              </div>
              <div className={Styles.ldMain}>
                {d?.deployments?.map((de, deI) => (
                  <div key={deI} className={Styles.role}>
                    <span>
                      {de?.role}
                      <span className={Styles.required}>
                        {de?.role?.toUpperCase() === "BUSINESS MANAGER" ||
                        de?.role?.toUpperCase() === "RECRUITMENT OFFICER"
                          ? "*"
                          : ""}
                      </span>
                    </span>
                    <div
                      ref={
                        activeCityID === d?.cityCode
                          ? searchTemplate[deI][2]
                          : searchTemplate[deI][5]
                      }
                      className={Styles.rSearchWrapper}
                    >
                      <input
                        value={searchTemplate[deI][0]}
                        onChange={(e) =>
                          searchTemplate[deI][1](e?.target?.value)
                        }
                        placeholder="Search"
                        onFocus={() => searchTemplate[deI][4](true)}
                        // onBlur={() => searchTemplate[deI][4](false)}
                        className={`${
                          alertFound?.includes(de?.role?.toLowerCase()) &&
                          Styles.required
                        }`}
                      />
                      <Icon
                        icon={"search"}
                        size={"24px"}
                        className={Styles.rSearchIcon}
                      />
                      {searchTemplate[deI][3] && (
                        <div className={Styles.rSearchOptions}>
                          {de?.delegations
                            ?.filter((obj) =>
                              obj?.name
                                ?.toLowerCase()
                                ?.includes(
                                  searchTemplate[deI][0]?.toLowerCase()
                                )
                            )
                            ?.sort((a, b) =>
                              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                            )
                            ?.map((u, uI) => (
                              <div key={uI} className={Styles.user}>
                                {de?.role !== "BUSINESS MANAGER" && (
                                  <CheckboxJobPosting
                                    checked={u?.isPicked}
                                    onChange={() => onSelect(u, de?.role)}
                                  />
                                )}
                                <span onClick={() => onSelect(u, de?.role)}>
                                  {u?.name}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                    {!!de?.delegations?.filter((obj) => obj?.isPicked)
                      ?.length ? (
                      <div className={Styles.delegatedWrapper}>
                        {de?.delegations
                          ?.filter((obj) => obj?.isPicked)
                          ?.map((u, uI) => (
                            <div key={uI} className={Styles.delegated}>
                              <Avatar sx={{ width: "32px", height: "32px" }} />
                              <span>{u?.name}</span>
                              <div className={Styles.dAction}>
                                {de?.role !== "BUSINESS MANAGER" &&
                                  (de?.role === "RECRUITMENT OFFICER" ? (
                                    !u?.delegationID ? (
                                      <button
                                        onClick={() => onSelect(u, de?.role)}
                                      >
                                        <Icon icon={"cross-circle"} size={18} />
                                      </button>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <button
                                      onClick={() => onSelect(u, de?.role)}
                                    >
                                      <Icon icon={"cross-circle"} size={18} />
                                    </button>
                                  ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className={Styles.empty}>
                        <img src={Images.GROUP_3456} alt="" />
                        <span>
                          Lakukan pencarian nama untuk menambahkan delegasi
                        </span>
                      </div>
                    )}
                    <div></div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className={Styles.noLocation}>
            <img src={Images.PANA} alt="default" />
            <div className={Styles.desc}>
              <h3>Belum Ada Data yang Dimasukan</h3>
              <p>
                Tambah lokasi yang diinginkan dan masukan pekerjaan yang
                dibutuhkan
              </p>
            </div>

            <button
              className={Styles.addLocationButton}
              onClick={() => setShowAdd(true)}
            >
              <Icon icon="circle-plus" size={20} />
              <span>Lokasi</span>
            </button>
          </div>
        )}
      </div>

      {showAdd && (
        <AddLocationModalV2
          // trigger={trigger}
          // setTrigger={setTrigger}
          show={showAdd}
          setShow={setShowAdd}
          cities={
            cities?.filter(
              (obj) =>
                !delegationsForm?.map((f) => f?.cityCode)?.includes(obj?.code)
            )
            // ?.filter((el) => {
            //   return delegationsForm.some((f) => {
            //     return f?.cityName?.toUpperCase() !== el?.name?.toUpperCase()
            //   });
            // })
          }
          setValues={(newValues) => {
            const newArr = [...delegationsForm];
            for (let i = 0; i < newValues.length; i++) {
              newArr.unshift({
                id: makeRandomString(5),
                cityCode: newValues[i].code,
                cityName: newValues[i].name,
                umk: newValues[i].umk || 4500000,
                deployments: defaultDeployments,
              });
            }
            setDelegationsForm(newArr);
          }}
        />
      )}
    </div>
  );
}
