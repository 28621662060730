/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import LoadingAnimation from "@Atom/LoadingAnimation";
import Spinner from "@Atom/Spinner";
import { fileBaseUrl } from "@Config/api";
import {
  getCandidateLogs,
  getDetailRecruitment,
} from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import moment from "moment";
import "moment/locale/id";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./styles.module.scss";

// Atur locale ke bahasa Indonesia
moment.locale("id");

export default function DetailCandidateRecruitment({
  handleClose,
  showDetailCandidate,
  isClient,
  isBS,
}) {
  const [selectedButton, setSelectedButton] = useState("Personal Data");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const containerRef = useRef(null);
  const timeoutRef = useRef(null);

  const [data, setData] = useState(null);
  const [dataLog, setDataLog] = useState([]);
  const maxLength = 22;

  const refetchData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = !isBS
        ? selectedButton === "Personal Data"
          ? await getDetailRecruitment(showDetailCandidate?.candidateId)
          : await getCandidateLogs(
              showDetailCandidate?.personId,
              selectedPage,
              limit
            )
        : await getCandidateLogs(
            showDetailCandidate?.personId,
            selectedPage,
            limit
          );

      if (!isBS) {
        if (selectedButton === "Personal Data") {
          setData(response);
          setDataLog([]);
          setSelectedPage(1);
        } else {
          setData(response);
          setDataLog((prevData) => [...prevData, ...response?.data]);
        }
      } else {
        setData(response);
        setDataLog((prevData) => [...prevData, ...response?.data]);
      }

      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  }, [
    showDetailCandidate?.candidateId,
    showDetailCandidate?.personId,
    selectedPage,
    limit,
    selectedButton,
    isBS,
  ]);

  useEffect(() => {
    if (showDetailCandidate) {
      refetchData();
    }
  }, [showDetailCandidate, refetchData]);

  useEffect(() => {
    if (selectedButton) {
      setSelectedButton(selectedButton);
    } else {
      setSelectedButton("Personal Data");
    }
  }, [selectedButton]);

  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const filteredDocument = useMemo(() => {
    if (data?.CandidateDocuments?.length > 0) {
      return data?.CandidateDocuments?.filter(
        (el) => el?.type !== "SELF PICTURE" && el?.type !== "FOTO SELFIE"
      );
    } else {
      return [];
    }
  }, [data?.CandidateDocuments]);

  const filteredPhotoProfile = useMemo(() => {
    if (data?.CandidateDocuments?.length > 0) {
      return data?.CandidateDocuments?.find(
        (el) => el?.type === "SELF PICTURE" || el?.type === "FOTO SELFIE"
      );
    } else {
      return null;
    }
  }, [data?.CandidateDocuments]);

  const handleDownload = (imageName) => {
    const imageUrl = fileBaseUrl + imageName;
    window.open(imageUrl, "_blank");
  };

  useEffect(() => {
    if (selectedButton === "History" || isBS) {
      let lastScrollTop = 0;

      const handleScroll = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          const container = containerRef.current;
          if (container) {
            // Mendapatkan posisi scroll saat ini
            const currentScrollTop = container.scrollTop;

            // Memeriksa arah scroll
            if (currentScrollTop > lastScrollTop) {
              // Scroll ke bawah
              lastScrollTop = currentScrollTop;

              // Lanjutkan dengan logika pengambilan data Anda
              if (
                (container.scrollHeight - container.scrollTop <=
                  container.clientHeight + 500 &&
                  !isLoadingData &&
                  dataLog.length === data?.totalData) ||
                dataLog.length > data?.totalData // Sesuaikan LIMIT sesuai batas data Anda
              ) {
                return; // Keluar jika panjang dataBatch kurang dari LIMIT
              }

              if (
                container.scrollHeight - container.scrollTop <=
                  container.clientHeight + 500 &&
                !isLoadingData
              ) {
                setSelectedPage((prev) => prev + 1);
                // refetchBatch(dataBatch?.slice(-1)[0]?._id);
              }
            } else {
              // Scroll ke atas, tidak melakukan apa pun atau tambahkan logika sesuai kebutuhan
            }

            // Update lastScrollTop ke nilai saat ini
            lastScrollTop = currentScrollTop;
          }
        }, 500); // Sesuaikan delay sesuai kebutuhan
      };

      const container = containerRef.current;
      if (container) {
        container.addEventListener("scroll", handleScroll);
        return () => {
          container.removeEventListener("scroll", handleScroll);
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        };
      }
    }
    // Deklarasikan variabel di luar fungsi handleScroll untuk menyimpan posisi scroll terakhir
  }, [isLoadingData, dataLog, selectedButton, data, isBS]);

  if (isBS) {
    return (
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <span>Detail Kandidat</span>
            <Icon
              icon={"cross"}
              size={24}
              color={"#0A0A0A"}
              className={Styles.iconCross}
              onClick={handleClose}
            />
          </div>
        </div>

        {isLoadingData && dataLog?.length === 0 ? (
          <div
            style={{
              // placeItems: 'center'
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingAnimation />
          </div>
        ) : !isLoadingData && dataLog?.length === 0 ? (
          <div
            style={{
              // placeItems: 'center'
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontSize: "14px", color: "#9E9E9E", fontWeight: "400" }}
            >
              Data log belum ditemukan
            </span>
          </div>
        ) : (
          <div className={Styles.contents} ref={containerRef}>
            <div className={Styles.candidateLogsbox}>
              {dataLog?.map((log, i) => {
                return (
                  <div className={Styles.logs} key={i}>
                    <div className={Styles.dividerLog}>
                      <div></div>
                      {i === dataLog?.length - 1 ? null : (
                        <div>
                          <img src={Images.LINE_401} alt="" />
                        </div>
                      )}
                    </div>

                    <div className={Styles.logList}>
                      <div className={Styles.listBox}>
                        <div>
                          <span>{log?.remark || "-"}</span>

                          <div>
                            {log?.createdAt
                              ? moment(new Date(log?.createdAt)).format(
                                  "dddd, DD MMM YYYY"
                                )
                              : "-"}
                            <span></span>
                            {log?.createdAt
                              ? moment(new Date(log?.createdAt)).format("HH:mm")
                              : "-"}
                          </div>
                          <div>
                            <div>
                              <Icon
                                icon={"bag-job"}
                                size={16}
                                color={"#1571DE"}
                              />
                            </div>
                            <span>{log?.jobPostingTitle || "-"}</span>
                            <span></span>
                            <span>{log?.clientName}</span>
                          </div>
                        </div>

                        {log?.remark === "Kandidat Dalam Status Hold" && (
                          <div>
                            Kandidat dalam status hold karena sedang dalam
                            proses untuk pekerjaan{" "}
                            <span>{log?.holdPosition || "-"}</span> di{" "}
                            <span>{log?.holdClient || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Offering Dikirimkan Kepada Kandidat" && (
                          <div>
                            Offering telah berhasil dikirimkan kepada kandidat.
                          </div>
                        )}

                        {log?.remark === "Offering Ditolak Oleh Kandidat" && (
                          <div>
                            Kandidat untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> telah menolak
                            offering yang diberikan.
                          </div>
                        )}

                        {log?.remark === "Offering Diterima Oleh Kandidat" && (
                          <div>
                            Kandidat untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> telah menerima
                            offering yang diberikan.
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Lolos Interview Klien dan Lanjut ke Step 5" && (
                          <div>
                            Kandidat telah berhasil lolos interview klien dan
                            telah berlanjut ke step 5: Undang Interview Klien.
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Menerima Undangan Interview Klien dan Lanjut Ke Step 4" && (
                          <div>
                            Kandidat telah menerima undangan interview klien dan
                            telah berlanjut ke step 4: Kirim Rekomendasi
                          </div>
                        )}

                        {log?.remark === "Kandidat Dikirimkan Kepada Klien" && (
                          <div>
                            Kandidat untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> telah berhasil
                            dikirimkan kepada klien.
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Menerima Undangan Interview CLIENT" && (
                          <div>
                            Kandidat menerima undangan pelaksanaan Interview
                            klien untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Menerima Undangan Interview Klien" && (
                          <div>
                            Kandidat menerima undangan pelaksanaan Interview
                            klien untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Undangan Interview Client Dikirim" && (
                          <div>
                            Kandidat diundang untuk melakukan Interview Klien
                            untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark?.includes("Kandidat Dikembalikan") && (
                          <div>
                            {log?.remark?.replace(
                              "Kandidat Dikembalikan",
                              "Kandidat telah dipindahkan kembali"
                            )}
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Tidak Lolos Interview Klien" && (
                          <div>
                            Kandidat untuk posisi
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> tidak berhasil
                            dalam interview klien.
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Lolos Interview RO dan Lanjut Ke Step 3" && (
                          <div>
                            Kandidat telah berhasil lolos interview RO dan telah
                            berlanjut ke step 3: Undang Interview Klien.
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Lolos Interview RO dan Lanjut Ke Step 5" && (
                          <div>
                            Kandidat telah berhasil lolos interview RO dan
                            langsung berlanjut ke step 5: offering.
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Menerima Undangan Interview RO" && (
                          <div>
                            Kandidat menerima undangan pelaksanaan Interview RO
                            untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark === "Undangan Interview RO Dikirim" && (
                          <div>
                            Kandidat diundang untuk melakukan Interview RO untuk
                            posisi <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark === "Melamar Pekerjaan dari Kerja 365" && (
                          <div>
                            Kandidat melamar pekerjaan untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> melalui
                            platform kerja365.id
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Menolak Undangan Interview RO" && (
                          <div>
                            Kandidat menolak undangan Interview RO untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Undangan Interview RO Dikirim Ulang" && (
                          <div>
                            Kandidat diundang ulang untuk melakukan Interview RO
                            untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Tidak Hadir Proses Interview RO" && (
                          <div>
                            Kandidat tidak hadir Interview RO untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Tidak Lolos Interview RO" && (
                          <div>
                            Kandidat tidak berhasil lolos dalam tahapan
                            interview RO untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark === "Undangan Interview Klien Dikirim" && (
                          <div>
                            Kandidat diundang untuk melakukan Interview Klien
                            untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Menolak Undangan Interview Klien" && (
                          <div>
                            Kandidat menolak undangan Interview klien untuk
                            posisi <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Menolak Undangan Interview CLIENT" && (
                          <div>
                            Kandidat menolak undangan Interview klien untuk
                            posisi <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Undangan Interview Klien Dikirim Ulang" && (
                          <div>
                            Kandidat diundang ulang untuk melakukan Interview
                            klien untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat Mengajukan Reschedule Undangan Interview RO" && (
                          <div>
                            Kandidat Mengajukan Reschedule Undangan Interview RO
                            untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Verifikasi Pertama: Data Ditolak BS" && (
                          <div>
                            Data kandidat untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> ditolak oleh{" "}
                            {log?.businessSupportName || "-"}
                            dan sedang diperbaiki oleh kandidat.
                          </div>
                        )}

                        {log?.remark === "Menunggu Generate Kontrak" && (
                          <div>
                            Kontrak kandidat untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> sedang dalam
                            pembuatan oleh BS.
                          </div>
                        )}

                        {log?.remark ===
                          "Kontrak Sedang Ditinjau dan Menunggu Approval BM" && (
                          <div>
                            Kontrak telah dibuat dan sedang menunggu approval
                            dari BM untuk dapat diteruskan kepada kandidat.
                          </div>
                        )}

                        {log?.remark === "Kontrak Ditolak BM" && (
                          <div>
                            Kontrak telah ditolak oleh BM dan sedang dalam
                            perbaikan oleh {log?.businessSupportName || "-"}.
                          </div>
                        )}
                        {log?.remark === "Menunggu TTD Kontrak Kandidat" && (
                          <div>
                            Kontrak telah dikirimkan kepada kandidat, dan sedang
                            dalam tahap menunggu tanda tangan dari kandidat.
                          </div>
                        )}

                        {log?.remark ===
                          "Verifikasi Kedua: Menunggu Approval BS" && (
                          <div>
                            Data kandidat untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> sedang dalam
                            tahap pemeriksaan dan menunggu approval BS.
                          </div>
                        )}

                        {log?.remark ===
                          "Verifikasi Kedua: Data Ditolak BS" && (
                          <div>
                            Data kandidat untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span> ditolak oleh{" "}
                            {log?.businessSupportName || "-"} dan sedang
                            diperbaiki oleh kandidat.
                          </div>
                        )}

                        {log?.remark === "Verifikasi Kedua: Gagal Inject" && (
                          <div>
                            Data kandidat telah berhasil di verifikasi oleh BS
                            namun gagal untuk inject.
                          </div>
                        )}

                        {log?.remark === "Kandidat Bergabung" && (
                          <div>
                            Kandidat telah berhasil di verifikasi kedua dan
                            telah bergabung bekerja untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark?.includes(
                          "Kandidat dialihkan manual ke Job Posting"
                        ) && (
                          <div>
                            Kandidat telah dialihkan secara manual untuk posisi{" "}
                            <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                            <span>{log?.clientName || "-"}</span>
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat dialihkan menjadi Private" && (
                          <div>
                            Kandidat telah berhasil telah berhasil dipindahkan
                            kedalam daftar kandidat private.
                          </div>
                        )}

                        {log?.remark ===
                          "Kandidat dialihkan menjadi Public" && (
                          <div>
                            Kandidat telah berhasil telah berhasil dipindahkan
                            kedalam daftar kandidat public.
                          </div>
                        )}
                      </div>

                      <div className={Styles.date}>
                        {log?.createdAt
                          ? moment(new Date(log?.createdAt)).format(
                              "dddd, DD MMM YYYY"
                            )
                          : "-"}
                        <span></span>
                        {log?.createdAt
                          ? moment(new Date(log?.createdAt)).format("HH:mm")
                          : "-"}
                      </div>
                    </div>
                  </div>
                );
              })}
              {isLoadingData && dataLog?.length > 0 && (
                <div className={Styles.loadingSpinner}>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.top}>
          <span>Detail Kandidat</span>
          <Icon
            icon={"cross"}
            size={24}
            color={"#0A0A0A"}
            className={Styles.iconCross}
            onClick={handleClose}
          />
        </div>

        <div className={Styles.bottom}>
          <div className={Styles.buttonSection}>
            <div
              className={`${
                selectedButton === "Personal Data" && Styles.active
              }`}
              onClick={() => {
                setSelectedButton("Personal Data");
              }}
            >
              <span>Personal Data</span>
              <Icon
                icon={
                  selectedButton === "Personal Data" ? "arrow-up" : "arrow-down"
                }
                size={16}
                color={selectedButton === "Personal Data" && "#1571DE"}
              />
            </div>

            <div
              className={`${selectedButton === "History" && Styles.active}`}
              onClick={() => {
                setSelectedButton("History");
              }}
            >
              <span>History</span>
              <Icon
                icon={selectedButton === "History" ? "arrow-up" : "arrow-down"}
                size={16}
                color={selectedButton === "History" && "#1571DE"}
              />
            </div>
          </div>

          {/* {selectedButton === "History" && (
            <div className={Styles.totalData}>
              Menampilkan {dataLog?.length || 0} dari {data?.totalData || 0}
            </div>
          )} */}
        </div>
      </div>

      {selectedButton === "Personal Data" ? (
        isLoadingData ? (
          <div
            style={{
              // placeItems: 'center'
              minHeight: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingAnimation />
          </div>
        ) : (
          <div className={Styles.contents}>
            <div className={Styles.personalDataBox}>
              <div className={Styles.profile}>
                <div className={Styles.images}>
                  <img
                    src={
                      filteredPhotoProfile
                        ? fileBaseUrl + filteredPhotoProfile?.url
                        : Images.AVA_DEFAULT
                    }
                    alt=""
                  />
                </div>
                <div className={Styles.listbox}>
                  <div className={Styles.nameBox}>
                    <span>{toTitleCase(data?.name) || "-"}</span>
                    {/* <span></span>
                    <span>{data?.ExpectedPosition?.name || "-"}</span> */}
                  </div>

                  <div className={Styles.list}>
                    <span>Informasi Umum</span>
                    <div>
                      <span>Posisi</span>
                      <span>: {data?.ExpectedPosition?.name || "-"}</span>
                    </div>

                    {isClient ? null : (
                      <div>
                        <span>Nik</span>
                        <span>: {data?.personId || "-"}</span>
                      </div>
                    )}

                    <div>
                      <span>Tanggal lahir</span>
                      <span>
                        :{" "}
                        {data?.dateOfBirth
                          ? moment(new Date(data?.dateOfBirth)).format(
                              "DD MMMM YYYY"
                            )
                          : "-"}
                      </span>
                    </div>

                    <div>
                      <span>Domisili</span>
                      <span>: {data?.domicile || "-"}</span>
                    </div>

                    <div>
                      <span>Alamat</span>
                      <span>: {data?.address || "-"}</span>
                    </div>

                    {isClient ? null : (
                      <div>
                        <span>Nomor Whatsapp</span>
                        <span>: +{data?.phoneNumber || "-"}</span>
                      </div>
                    )}

                    {isClient ? null : (
                      <div>
                        <span>Email</span>
                        <span>: {data?.email || "-"}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={Styles.experiencesBox}>
                <span>Pengalaman Kerja</span>
                {data?.CandidateWorkExperiences?.length > 0 ? (
                  <div className={`${Styles.experincesList}`}>
                    {data?.CandidateWorkExperiences?.map((el, idx) => {
                      return (
                        <div
                          className={`${Styles.list} ${
                            idx ===
                              data?.CandidateWorkExperiences?.length - 1 &&
                            Styles.noBorder
                          }`}
                          key={idx}
                        >
                          <span>{el?.companyName || "-"}</span>
                          <div>
                            <span>Mulai bekerja</span>
                            <span>
                              {el?.startMonth ? el?.startMonth : null}{" "}
                              {el?.startYear || "-"}
                            </span>
                          </div>

                          <div>
                            <span>Selesai bekerja</span>
                            <span>
                              {el?.endYear !== "-" && el?.endMonth !== "-"
                                ? `${el?.endMonth ? el?.endMonth : ""} ${
                                    el?.endYear ? el?.endYear : ""
                                  }`
                                : "Masih bekerja sampai saat ini"}
                            </span>
                          </div>

                          <div>
                            <span>Deskripsi</span>
                            <span>{el?.description || "-"}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span className={Styles.notFound}>Tanpa Pengalaman</span>
                )}
              </div>

              <div className={Styles.experiencesBox}>
                <span>Pendidikan Terakhir</span>
                <span className={Styles.notFound}>
                  {data?.lastEducation || "-"}
                </span>
              </div>

              <div className={Styles.experiencesBox}>
                <span>Informasi Lainnya</span>
                <div className={Styles.informationList}>
                  <span>Kemampuan bahasa inggris</span>
                  <span>{data?.englishLevel || "-"}</span>
                </div>

                <div className={Styles.informationList}>
                  <span>Apakah memiliki Kendaraan Sendiri</span>
                  <span>{data?.vehicle || "-"}</span>
                </div>
              </div>

              <div className={`${Styles.experiencesBox} ${Styles.document}`}>
                <span>Dokumen</span>
                {filteredDocument?.length > 0 ? (
                  <div className={Styles.documentList}>
                    {filteredDocument?.map((el, idx) => {
                      return (
                        <div className={Styles.doc} key={idx}>
                          <div>
                            <Icon icon={"document-text"} size={20} />
                            <span>{el?.url || "-"}</span>
                          </div>

                          <Icon
                            icon={"download"}
                            size={20}
                            color={"#1571DE"}
                            className={Styles.icon}
                            onClick={() => {
                              handleDownload(el?.url);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span className={Styles.notFound}>Tidak ada dokumen</span>
                )}
              </div>
            </div>
          </div>
        )
      ) : isLoadingData && dataLog?.length === 0 ? (
        <div
          style={{
            // placeItems: 'center'
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingAnimation />
        </div>
      ) : !isLoadingData && dataLog?.length === 0 ? (
        <div
          style={{
            // placeItems: 'center'
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{ fontSize: "14px", color: "#9E9E9E", fontWeight: "400" }}
          >
            Data log belum ditemukan
          </span>
        </div>
      ) : (
        <div className={Styles.contents} ref={containerRef}>
          <div className={Styles.candidateLogsbox}>
            {dataLog?.map((log, i) => {
              return (
                <div className={Styles.logs} key={i}>
                  <div className={Styles.dividerLog}>
                    <div></div>
                    {i === dataLog?.length - 1 ? null : (
                      <div>
                        <img src={Images.LINE_401} alt="" />
                      </div>
                    )}
                  </div>

                  <div className={Styles.logList}>
                    <div className={Styles.listBox}>
                      <div>
                        <span>{log?.remark || "-"}</span>

                        <div>
                          {log?.createdAt
                            ? moment(new Date(log?.createdAt)).format(
                                "dddd, DD MMM YYYY"
                              )
                            : "-"}
                          <span></span>
                          {log?.createdAt
                            ? moment(new Date(log?.createdAt)).format("HH:mm")
                            : "-"}
                        </div>
                        <div>
                          <div>
                            <Icon
                              icon={"bag-job"}
                              size={16}
                              color={"#1571DE"}
                            />
                          </div>
                          <span>{log?.jobPostingTitle || "-"}</span>
                          <span></span>
                          <span>{log?.clientName}</span>
                        </div>
                      </div>

                      {log?.remark === "Kandidat Dalam Status Hold" && (
                        <div>
                          Kandidat dalam status hold karena sedang dalam proses
                          untuk pekerjaan{" "}
                          <span>{log?.holdPosition || "-"}</span> di{" "}
                          <span>{log?.holdClient || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Offering Dikirimkan Kepada Kandidat" && (
                        <div>
                          Offering telah berhasil dikirimkan kepada kandidat.
                        </div>
                      )}

                      {log?.remark === "Offering Ditolak Oleh Kandidat" && (
                        <div>
                          Kandidat untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> telah menolak
                          offering yang diberikan.
                        </div>
                      )}

                      {log?.remark === "Offering Diterima Oleh Kandidat" && (
                        <div>
                          Kandidat untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> telah menerima
                          offering yang diberikan.
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Lolos Interview Klien dan Lanjut ke Step 5" && (
                        <div>
                          Kandidat telah berhasil lolos interview klien dan
                          telah berlanjut ke step 5: Undang Interview Klien.
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Menerima Undangan Interview Klien dan Lanjut Ke Step 4" && (
                        <div>
                          Kandidat telah menerima undangan interview klien dan
                          telah berlanjut ke step 4: Kirim Rekomendasi
                        </div>
                      )}

                      {log?.remark === "Kandidat Dikirimkan Kepada Klien" && (
                        <div>
                          Kandidat untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> telah berhasil
                          dikirimkan kepada klien.
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Menerima Undangan Interview CLIENT" && (
                        <div>
                          Kandidat menerima undangan pelaksanaan Interview klien
                          untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Menerima Undangan Interview Klien" && (
                        <div>
                          Kandidat menerima undangan pelaksanaan Interview klien
                          untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark === "Undangan Interview Client Dikirim" && (
                        <div>
                          Kandidat diundang untuk melakukan Interview Klien
                          untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark?.includes("Kandidat Dikembalikan") && (
                        <div>
                          {log?.remark?.replace(
                            "Kandidat Dikembalikan",
                            "Kandidat telah dipindahkan kembali"
                          )}
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Tidak Lolos Interview Klien" && (
                        <div>
                          Kandidat untuk posisi
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> tidak berhasil
                          dalam interview klien.
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Lolos Interview RO dan Lanjut Ke Step 3" && (
                        <div>
                          Kandidat telah berhasil lolos interview RO dan telah
                          berlanjut ke step 3: Undang Interview Klien.
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Lolos Interview RO dan Lanjut Ke Step 5" && (
                        <div>
                          Kandidat telah berhasil lolos interview RO dan
                          langsung berlanjut ke step 5: offering.
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Menerima Undangan Interview RO" && (
                        <div>
                          Kandidat menerima undangan pelaksanaan Interview RO
                          untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark === "Undangan Interview RO Dikirim" && (
                        <div>
                          Kandidat diundang untuk melakukan Interview RO untuk
                          posisi <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark === "Melamar Pekerjaan dari Kerja 365" && (
                        <div>
                          Kandidat melamar pekerjaan untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> melalui platform
                          kerja365.id
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Menolak Undangan Interview RO" && (
                        <div>
                          Kandidat menolak undangan Interview RO untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Undangan Interview RO Dikirim Ulang" && (
                        <div>
                          Kandidat diundang ulang untuk melakukan Interview RO
                          untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Tidak Hadir Proses Interview RO" && (
                        <div>
                          Kandidat tidak hadir Interview RO untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark === "Kandidat Tidak Lolos Interview RO" && (
                        <div>
                          Kandidat tidak berhasil lolos dalam tahapan interview
                          RO untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark === "Undangan Interview Klien Dikirim" && (
                        <div>
                          Kandidat diundang untuk melakukan Interview Klien
                          untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Menolak Undangan Interview Klien" && (
                        <div>
                          Kandidat menolak undangan Interview klien untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Menolak Undangan Interview CLIENT" && (
                        <div>
                          Kandidat menolak undangan Interview klien untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Undangan Interview Klien Dikirim Ulang" && (
                        <div>
                          Kandidat diundang ulang untuk melakukan Interview
                          klien untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Kandidat Mengajukan Reschedule Undangan Interview RO" && (
                        <div>
                          Kandidat Mengajukan Reschedule Undangan Interview RO
                          untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark ===
                        "Verifikasi Pertama: Data Ditolak BS" && (
                        <div>
                          Data kandidat untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> ditolak oleh{" "}
                          {log?.businessSupportName || "-"}
                          dan sedang diperbaiki oleh kandidat.
                        </div>
                      )}

                      {log?.remark === "Menunggu Generate Kontrak" && (
                        <div>
                          Kontrak kandidat untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> sedang dalam
                          pembuatan oleh BS.
                        </div>
                      )}

                      {log?.remark ===
                        "Kontrak Sedang Ditinjau dan Menunggu Approval BM" && (
                        <div>
                          Kontrak telah dibuat dan sedang menunggu approval dari
                          BM untuk dapat diteruskan kepada kandidat.
                        </div>
                      )}

                      {log?.remark === "Kontrak Ditolak BM" && (
                        <div>
                          Kontrak telah ditolak oleh BM dan sedang dalam
                          perbaikan oleh {log?.businessSupportName || "-"}.
                        </div>
                      )}
                      {log?.remark === "Menunggu TTD Kontrak Kandidat" && (
                        <div>
                          Kontrak telah dikirimkan kepada kandidat, dan sedang
                          dalam tahap menunggu tanda tangan dari kandidat.
                        </div>
                      )}

                      {log?.remark ===
                        "Verifikasi Kedua: Menunggu Approval BS" && (
                        <div>
                          Data kandidat untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> sedang dalam
                          tahap pemeriksaan dan menunggu approval BS.
                        </div>
                      )}

                      {log?.remark === "Verifikasi Kedua: Data Ditolak BS" && (
                        <div>
                          Data kandidat untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span> ditolak oleh{" "}
                          {log?.businessSupportName || "-"} dan sedang
                          diperbaiki oleh kandidat.
                        </div>
                      )}

                      {log?.remark === "Verifikasi Kedua: Gagal Inject" && (
                        <div>
                          Data kandidat telah berhasil di verifikasi oleh BS
                          namun gagal untuk inject.
                        </div>
                      )}

                      {log?.remark === "Kandidat Bergabung" && (
                        <div>
                          Kandidat telah berhasil di verifikasi kedua dan telah
                          bergabung bekerja untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark?.includes(
                        "Kandidat dialihkan manual ke Job Posting"
                      ) && (
                        <div>
                          Kandidat telah dialihkan secara manual untuk posisi{" "}
                          <span>{log?.jobPostingTitle || "-"}</span> di{" "}
                          <span>{log?.clientName || "-"}</span>
                        </div>
                      )}

                      {log?.remark === "Kandidat dialihkan menjadi Private" && (
                        <div>
                          Kandidat telah berhasil telah berhasil dipindahkan
                          kedalam daftar kandidat private.
                        </div>
                      )}

                      {log?.remark === "Kandidat dialihkan menjadi Public" && (
                        <div>
                          Kandidat telah berhasil telah berhasil dipindahkan
                          kedalam daftar kandidat public.
                        </div>
                      )}
                    </div>

                    <div className={Styles.date}>
                      {log?.createdAt
                        ? moment(new Date(log?.createdAt)).format(
                            "dddd, DD MMM YYYY"
                          )
                        : "-"}
                      <span></span>
                      {log?.createdAt
                        ? moment(new Date(log?.createdAt)).format("HH:mm")
                        : "-"}
                    </div>
                  </div>
                </div>
              );
            })}
            {isLoadingData && dataLog?.length > 0 && (
              <div className={Styles.loadingSpinner}>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
