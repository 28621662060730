import { getFLBData } from "@Services/auth";
import {
  Document,
  Image,
  Link,
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import "moment/locale/id";
import { useEffect, useMemo, useState } from "react";
import { json, useLoaderData } from "react-router-dom";
// import EMAIL from "../../../../assets/Images/email-icon.png";
import { fileBaseUrl } from "@Config/api";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";
import { getLogFLB, getSchemeDetail } from "@Services/manager/client";
import Images from "@Theme/Images";
import { isMobile } from "react-device-detect";
import GLOBE from "../../../../assets/Images/globe-icon.png";
import PERMATA_ICON from "../../../../assets/Images/permata-logo.png";
import TELEPHONE from "../../../../assets/Images/telephone-icon.png";
import CHECKLIST_ICON from "../../../../assets/Images/checklist-green.png";
import CLOCK_ICON from "../../../../assets/Images/clock-yellow.png";
import WARNING_ICON from "../../../../assets/Images/warning-red.png";
import Avatar from "./avatar";
import CostEstimation from "./costEstimation";
import MobileStyles from "./style.module.scss";
import Styles from "./styles";
import TermOfPayment from "./termOfPayment";
import CustomText from "./text";

let IDR = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  minimumFractionDigits: 0,
});

export async function pdfPreviewLoader({ params }) {
  const id = params.id;

  try {
    const { response } = await getSchemeDetail(id);

    return {
      flbData: response,
    };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data.error);
      throw json(
        {
          message: err.response.data.error,
        },
        { status: err.response.data.code }
      );
    } else {
      console.log(err);
    }
    console.log(err, "error saat get flb data");
  }
}

export async function logHistoryLoader({ params }) {
  const { id } = params;

  try {
    // const countData = await getLogFLB(id, 1, 1);
    const { response } = await getLogFLB(id, 1, 0);

    return {
      flbData: response,
      type: "FLB_LOG_HISTORY",
    };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data.error);
      throw json(
        {
          message: err.response.data.error,
        },
        { status: err.response.data.code }
      );
    } else {
      console.log(err);
    }
    console.log(err, "error saat get flb log history");
  }
}

export async function loader({ params }) {
  const hash = params.hash;

  try {
    const { response } = await getFLBData(hash);

    return {
      flbData: response,
    };
  } catch (err) {
    if (err.response) {
      console.log(err.response.data.error);
      throw json(
        {
          message: err.response.data.error,
        },
        { status: err.response.data.code }
      );
    } else {
      console.log(err);
    }
    console.log(err, "error saat get flb data");
  }
}

const Doc = () => {
  const { flbData, type } = useLoaderData();
  const [workOrder] = useState(flbData);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const orderComprehensive = useMemo(() => {
    return workOrder?.orderDetail?.orderComprehensives;
  }, [workOrder]);

  const noBPJS = useMemo(() => {
    return (
      (!orderComprehensive?.PendaftaranBPJS || orderComprehensive?.PendaftaranBPJS === 'null')
      &&
      (!orderComprehensive?.BuktiPendaftaranBPJS || orderComprehensive?.BuktiPendaftaranBPJS === 'null')
      &&
      (!orderComprehensive?.PotonganBPJSTKO || orderComprehensive?.PotonganBPJSTKO === 'null')
    )
  }, [orderComprehensive?.BuktiPendaftaranBPJS, orderComprehensive?.PendaftaranBPJS, orderComprehensive?.PotonganBPJSTKO])

  if (type === "FLB_LOG_HISTORY") {
    return (
      <>
        {windowSize[0] < 768 || isMobile ? (
          <div className={MobileStyles.container}>
            <img src={Images.AUTH_BG} alt="bg" />
            <img src={Images.KERJA365_LIGHT} alt="logo" />
            <h3>FLB Log History</h3>
            <span>Click the button bellow to download the file</span>
            <PDFDownloadLink
              document={<HistoryLogFLB workOrder={workOrder} />}
              fileName={
                workOrder?.orderDetail?.ClientContractLogs?.flbNumber
                  ? workOrder.orderDetail.ClientContractLogs.flbNumber + ".pdf"
                  : "flb.pdf"
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <button disabled>
                    <span>Loading document...</span>
                  </button>
                ) : (
                  <button>
                    <span>Download now</span>
                  </button>
                )
              }
            </PDFDownloadLink>
          </div>
        ) : (
          <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
            <HistoryLogFLB workOrder={workOrder} />
          </PDFViewer>
        )}
      </>
    );
  } else {
    return (
      <>
        {windowSize[0] < 768 || isMobile ? (
          <div className={MobileStyles.container}>
            <img src={Images.AUTH_BG} alt="bg" />
            <img src={Images.KERJA365_LIGHT} alt="logo" />
            <h3>FLB Digital</h3>
            <span>Click the button bellow to download the file</span>
            <PDFDownloadLink
              document={<FLB workOrder={workOrder} noBPJS={noBPJS} />}
              fileName={
                workOrder?.clientProject?.docNumber
                  ? "FLB/" + workOrder?.clientProject?.docNumber ||
                  workOrder?.contract?.docNumber + ".pdf"
                  : "flb.pdf"
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <button disabled>
                    <span>Loading document...</span>
                  </button>
                ) : (
                  <button>
                    <span>Download now</span>
                  </button>
                )
              }
            </PDFDownloadLink>
          </div>
        ) : (
          <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
            <FLB workOrder={workOrder} noBPJS={noBPJS} />
          </PDFViewer>
        )}
      </>
    );
  }
};

const HistoryLogFLB = ({ workOrder }) => {
  const countLog = workOrder?.logs?.length;

  return (
    <Document
      title={`${workOrder?.orderDetail?.ClientContractLogs?.flbNumber}`}
    >
      <Page size="A4" style={Styles.page}>
        <View style={Styles.headerWrapper}>
          <View style={Styles.companyIconWrapper}>
            <View style={Styles.companyIcon}>
              <Image
                source={PERMATA_ICON}
                style={{ height: 52.5 / 2, width: 36 / 2 }}
              />
            </View>
            <CustomText color="#000" size={22 / 2} type="bold">
              PT Permata Indo Sejahtera
            </CustomText>
          </View>
          <View style={Styles.docNumberWrapper}>
            <CustomText color="#000" size={26 / 2} type="bold">
              Form Layanan Bisnis
            </CustomText>
            <View style={Styles.docNumber}>
              <CustomText color="#000" size={18 / 2}>
                {workOrder?.orderDetail?.ClientContractLogs?.flbNumber || "-"}
              </CustomText>
            </View>
          </View>
        </View>
        {/* Divider */}
        <View style={Styles.divider} />
        {/* Title */}
        <View style={Styles.titleContentWrapper}>
          <View style={Styles.logIconWrapper}>
            <Image
              source={Images.CLOCK_BACK}
              style={{ height: 30 / 2, width: 30 / 2 }}
            />
          </View>
          <CustomText color="#000" size={24 / 2} type="bold">
            Log History FLB
          </CustomText>
        </View>

        {/* Content */}
        <View style={Styles.logContainer}>
          {workOrder.logs.map((elem, index) => {
            return (
              <View style={Styles.logBox} wrap={false}>
                <View style={Styles.logUserNameWrapper}>
                  <Text style={Styles.logUserNameText}>
                    {capitalizedFirstLetter(elem?.businessManager?.name)}
                  </Text>
                </View>
                <View style={Styles.linkAvatar}>
                  <Avatar
                    imageUrl={elem?.businessManager?.profilePicture}
                    name={elem?.businessManager?.name}
                    size={40 / 2}
                    initialsSize={20 / 2}
                  />
                  {countLog > index + 1 && (
                    <View style={Styles.verticalDivider} />
                  )}
                </View>
                <View style={Styles.logWrapper}>
                  <View style={Styles.logContentWrapper}>
                    <Text style={Styles.logTitleText}>
                      {elem?.remark ? elem?.remark : "-"}
                    </Text>
                    <Text style={Styles.logText}>
                      Before : {elem?.prevValue ? elem?.prevValue : "-"}
                    </Text>
                    <Text style={Styles.logText}>
                      After : {elem?.updateValue ? elem?.updateValue : "-"}
                    </Text>
                  </View>
                  <View style={Styles.logDateWrapper}>
                    <Text style={Styles.logText}>
                      {elem?.updatedAt
                        ? moment(elem?.updatedAt).format("DD MMM YYYY, hh:mm")
                        : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

const FLBStatus = ({ contractStatus, schemeID }) => {
  console.log("contract", contractStatus)
  switch (contractStatus) {
    case "Active":
      return (
        <View style={Styles.statusSuccessFLBWrapper}>
          <Text>FLB {schemeID} telah disetujui</Text>
          <Image
            source={CHECKLIST_ICON}
            style={{ height: 24 / 2, width: 24 / 2 }}
          />
        </View>
      )
    case "Waiting":
      return (
        <View style={Styles.statusWaitingFLBWrapper}>
          <Text>FLB {schemeID} masih menunggu persetujuan</Text>
          <Image
            source={CLOCK_ICON}
            style={{ height: 24 / 2, width: 24 / 2 }}
          />
        </View>
      )
    case "Expired":
      return (
        <View style={Styles.statusExpiredFLBWrapper}>
          <Text>FLB {schemeID} telah expired</Text>
          <Image
            source={WARNING_ICON}
            style={{ height: 24 / 2, width: 24 / 2 }}
          />
        </View>
      )
    default:
      return (
        <View style={Styles.statusWaitingFLBWrapper}>
          <Text>FLB {schemeID} masih menunggu persetujuan</Text>
          <Image
            source={CLOCK_ICON}
            style={{ height: 24 / 2, width: 24 / 2 }}
          />
        </View>
      )
  }
}

const FLB = ({ workOrder, noBPJS = false }) => {
  return (
    <Document
      title={
        "FLB/" +
        (workOrder?.clientProject?.docNumber || workOrder?.contract?.docNumber)
      }
    >
      <Page size="A4" style={Styles.page}>
        <FLBStatus contractStatus={workOrder?.orderDetail?.contractStatus} schemeID={workOrder?.contract?.schemeID} />
        <View style={Styles.titleWrapper}>
          <View style={Styles.companyIconWrapper}>
            <View style={Styles.companyIcon}>
              <Image
                source={PERMATA_ICON}
                style={{ height: 52.5 / 2, width: 36 / 2 }}
              />
            </View>
            <CustomText color="#000" size={22 / 2} type="bold">
              PT Permata Indo Sejahtera
            </CustomText>
          </View>
          <View style={Styles.docNumberWrapper}>
            <CustomText color="#000" size={26 / 2} type="bold">
              Form Layanan Bisnis
            </CustomText>
            <View style={Styles.docNumber}>
              <CustomText color="#000" size={18 / 2}>
                No.{workOrder?.contract?.schemeID || "-"}
              </CustomText>
            </View>
          </View>
        </View>
        <View style={Styles.section}>
          <View style={{ paddingBottom: 10 / 2 }}>
            <CustomText size={18 / 2} color="#000">
              Kepada:
            </CustomText>
          </View>
          <View style={{ display: "flex", gap: 4, flexDirection: "column" }}>
            <CustomText size={18 / 2} color="#000" type="bold">
              {workOrder?.clientFrom?.name || "-"}
            </CustomText>
            <CustomText size={16 / 2} color="#000">
              {workOrder?.clientFrom?.address || "-"}
            </CustomText>
          </View>
        </View>
        <View style={Styles.section}>
          <View style={{ paddingBottom: 10 / 2 }}>
            <CustomText size={18 / 2} color="#000">
              Tanggal:
            </CustomText>
          </View>
          <CustomText size={16 / 2} color="#000">
            {moment(workOrder?.dateProject).format("LL") || "-"}
          </CustomText>
        </View>
        <View style={Styles.section}>
          <View style={{ paddingBottom: 10 / 2 }}>
            <CustomText size={18 / 2} color="#000">
              Proyek:
            </CustomText>
          </View>
          <View style={{ display: "flex", gap: 4, flexDirection: "column" }}>
            <CustomText size={18 / 2} color="#000" type="bold">
              {workOrder?.clientProject?.name ||
                workOrder?.orderDetail?.name ||
                "-"}
            </CustomText>
            <CustomText size={16 / 2} color="#000">
              {workOrder?.clientProject?.address ||
                workOrder?.orderDetail?.address ||
                "-"}
            </CustomText>
          </View>
        </View>
        <View style={Styles.section}>
          <View style={{ paddingBottom: 10 / 2 }}>
            <CustomText size={18 / 2} color="#000">
              Model Kerjasama:
            </CustomText>
          </View>
          <CustomText size={18 / 2} color="#000" type="bold">
            {workOrder?.cooperationModels || "-"}
          </CustomText>
        </View>

        {/* <--------- Information data table --------> */}
        <View style={[Styles.table, Styles.radius]}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F3F9FF",
              paddingTop: 10 / 2,
              paddingBottom: 10 / 2,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <CustomText size={22 / 2} color="#000" type="bold">
              Information Data
            </CustomText>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Periode Kerjasama
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={Styles.content}>
                <CustomText size={14 / 2} color="#9e9e9e">
                  Tanggal mulai
                </CustomText>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {moment(workOrder?.contract?.startDate).format("LL") || "-"}
                </CustomText>
              </View>
              <View style={Styles.content}>
                <CustomText size={14 / 2} color="#9e9e9e">
                  Tanggal berakhir
                </CustomText>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {moment(workOrder?.contract?.endDate).format("LL") || "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Tipe Kontrak
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={Styles.content}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.contractType || "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Kontak Person Klien
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={Styles.content}>
                <View style={{ paddingBottom: 4 / 2 }}>
                  <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                    Payroll
                  </CustomText>
                </View>
                {workOrder?.clientProject?.contacts
                  ?.filter((val) => val.workUnit === "PAYROLL")
                  .map((data, id) => (
                    <>
                      <View style={Styles.cellContentWrapper} key={id}>
                        <CustomText size={14 / 2} color="#9e9e9e">
                          Nama
                        </CustomText>
                        <CustomText size={16 / 2} color="#0a0a0a">
                          {data?.name || "-"}
                        </CustomText>
                      </View>
                      <View style={Styles.cellContentWrapper}>
                        <CustomText size={14 / 2} color="#9e9e9e">
                          Telp/HP
                        </CustomText>
                        <CustomText size={16 / 2} color="#0a0a0a">
                          {data?.phoneNumber || "-"}
                        </CustomText>
                      </View>
                      <View style={Styles.cellContentWrapper}>
                        <CustomText size={14 / 2} color="#9e9e9e">
                          Email
                        </CustomText>
                        <CustomText size={16 / 2} color="#0a0a0a">
                          {data?.email || "-"}
                        </CustomText>
                      </View>
                    </>
                  ))}
              </View>
              <View style={Styles.content}>
                <View style={{ paddingBottom: 4 / 2 }}>
                  <CustomText size={18 / 2} type="bold" color="#0a0a0a">
                    Invoice
                  </CustomText>
                </View>
                {workOrder?.clientProject?.contacts
                  ?.filter((val) => val.workUnit === "INVOICE")
                  .map((data, id) => (
                    <>
                      <View style={Styles.cellContentWrapper} key={id}>
                        <CustomText size={14 / 2} color="#9e9e9e">
                          Nama
                        </CustomText>
                        <CustomText size={16 / 2} color="#0a0a0a">
                          {data?.name || "-"}
                        </CustomText>
                      </View>
                      <View style={Styles.cellContentWrapper}>
                        <CustomText size={14 / 2} color="#9e9e9e">
                          Telp/HP
                        </CustomText>
                        <CustomText size={16 / 2} color="#0a0a0a">
                          {data?.phoneNumber || "-"}
                        </CustomText>
                      </View>
                      <View style={Styles.cellContentWrapper}>
                        <CustomText size={14 / 2} color="#9e9e9e">
                          Email
                        </CustomText>
                        <CustomText size={16 / 2} color="#0a0a0a">
                          {data?.email || "-"}
                        </CustomText>
                      </View>
                    </>
                  ))}
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                NPWP Klien
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={Styles.content}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.clientProject?.taxNumber || "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Flow Invoice
              </CustomText>
            </View>
            <View style={[Styles.contentWrapper, { flexDirection: "column" }]}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={Styles.content}>
                  <View style={Styles.cellContentWrapper}>
                    <CustomText size={14 / 2} color="#9e9e9e">
                      UP di invoice
                    </CustomText>
                    <CustomText size={16 / 2} color="#0a0a0a">
                      {workOrder?.orderDetail?.orderInvoiceFlows?.UP || "-"}
                    </CustomText>
                  </View>
                  <View style={Styles.cellContentWrapper}>
                    <CustomText size={14 / 2} color="#9e9e9e">
                      Tanggal pengajuan soft copy invoice
                    </CustomText>
                    <CustomText size={16 / 2} color="#0a0a0a">
                      {workOrder?.orderDetail?.orderInvoiceFlows
                        ?.TanggalPengajuanSoftCopyInvoice || "-"}
                    </CustomText>
                  </View>
                  <View style={Styles.cellContentWrapper}>
                    <CustomText size={14 / 2} color="#9e9e9e">
                      Tanggal mulai penggajian
                    </CustomText>
                    <CustomText size={16 / 2} color="#0a0a0a">
                      {workOrder?.orderDetail?.orderInvoiceFlows
                        ?.TanggalMulaiPenggajian || "-"}
                    </CustomText>
                  </View>
                </View>
                <View style={Styles.content}>
                  <View style={Styles.cellContentWrapper}>
                    <CustomText size={14 / 2} color="#9e9e9e">
                      Tanggal diterima invoice
                    </CustomText>
                    <CustomText size={16 / 2} color="#0a0a0a">
                      {workOrder?.orderDetail?.orderInvoiceFlows
                        ?.TanggalDiterimaData || "-"}
                    </CustomText>
                  </View>
                  <View style={Styles.cellContentWrapper}>
                    <CustomText size={14 / 2} color="#9e9e9e">
                      Tanggal hard copy diterima
                    </CustomText>
                    <CustomText size={16 / 2} color="#0a0a0a">
                      {workOrder?.orderDetail?.orderInvoiceFlows
                        ?.TanggalHardCopyInvoiceDiterima || "-"}
                    </CustomText>
                  </View>
                  <View style={Styles.cellContentWrapper}>
                    <CustomText size={14 / 2} color="#9e9e9e">
                      Tanggal cut off absensi
                    </CustomText>
                    <CustomText size={16 / 2} color="#0a0a0a">
                      {workOrder?.orderDetail?.orderInvoiceFlows
                        ?.TanggalCutOffAbsensi || "-"}
                    </CustomText>
                  </View>
                </View>
              </View>
              <View>
                <View style={Styles.cellContentWrapper}>
                  <CustomText size={14 / 2} color="#9e9e9e">
                    Dokumen pendukung invoice
                  </CustomText>
                  <CustomText size={16 / 2} color="#0a0a0a">
                    {workOrder?.orderDetail?.orderInvoiceFlows
                      ?.DokumenPendukungInvoice !== "null" &&
                      workOrder?.orderDetail?.orderInvoiceFlows
                        ?.DokumenPendukungInvoice
                      ? workOrder?.orderDetail?.orderInvoiceFlows
                        ?.DokumenPendukungInvoice
                      : "-"}
                  </CustomText>
                </View>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Rekening penerima Invoice
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={Styles.content}>
                <View style={Styles.cellContentWrapper}>
                  <CustomText size={14 / 2} color="#9e9e9e">
                    Perusahaan penerima
                  </CustomText>
                  <CustomText size={16 / 2} color="#0a0a0a">
                    {workOrder?.orderDetail?.bankRecipient || "-"}
                  </CustomText>
                </View>
                <View style={Styles.cellContentWrapper}>
                  <CustomText size={14 / 2} color="#9e9e9e">
                    Bank
                  </CustomText>
                  <CustomText size={16 / 2} color="#0a0a0a">
                    {workOrder?.orderDetail?.bankName || "-"}
                  </CustomText>
                </View>
              </View>
              <View style={[Styles.content, { justifyContent: "flex-end" }]}>
                <View style={Styles.cellContentWrapper}>
                  <CustomText size={14 / 2} color="#9e9e9e">
                    Nomor Rekening
                  </CustomText>
                  <CustomText size={16 / 2} color="#0a0a0a">
                    {workOrder?.orderDetail?.bankNumber || "-"}
                  </CustomText>
                </View>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Absen Dipotong Bila
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={[Styles.content, { width: "100%" }]}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives
                    ?.KetentuanPotongAbsen || "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={[Styles.row, { borderBottomWidth: 0 }]}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Keterangan Lainnya
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={[Styles.content, { width: "100%" }]}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives
                    ?.KeteranganLainnya || "-"}
                </CustomText>
              </View>
            </View>
          </View>
        </View>

        {/* <--------- Contract List --------> */}
        <View style={[Styles.table, Styles.radius]} wrap={false}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F3F9FF",
              paddingTop: 10 / 2,
              paddingBottom: 10 / 2,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <CustomText size={22 / 2} color="#000" type="bold">
              Daftar Dokumen Legal
            </CustomText>
          </View>
          <View style={Styles.contractTable}>
            <View style={Styles.ctHeader}>
              <View style={Styles.ctCellFirst}>
                <CustomText type={"bold"} size={16 / 2} color="#0a0a0a">
                  Document Number & Type
                </CustomText>
              </View>
              <View style={Styles.ctCellSecond}>
                <CustomText type={"bold"} size={16 / 2} color="#0a0a0a">
                  Upload Date & Time
                </CustomText>
              </View>
              <View style={Styles.ctCellThird}>
                <CustomText type={"bold"} size={16 / 2} color="#0a0a0a">
                  Start-End Date
                </CustomText>
              </View>
              <View style={Styles.ctCellFourth}>
                <CustomText type={"bold"} size={16 / 2} color="#0a0a0a">
                  Attachment
                </CustomText>
              </View>
              <View style={Styles.ctCellFifth}>
                <CustomText type={"bold"} size={16 / 2} color="#0a0a0a">
                  Status
                </CustomText>
              </View>
            </View>
            <View style={Styles.ctBody}>
              {workOrder?.contractLogs?.length ? (
                workOrder?.contractLogs?.map((c, cI) => (
                  <View
                    key={cI}
                    style={[
                      Styles.ctRow,
                      cI % 2 === 0 ? Styles.odd : "",
                      cI === workOrder?.contractLogs?.length - 1
                        ? Styles.last
                        : "",
                    ]}
                  >
                    <View style={[Styles.ctCellFirst, Styles.ctDocNumber]}>
                      <CustomText size={16 / 2} color="#0a0a0a">
                        {c?.flbNumber || c?.docNumber || "-"}
                      </CustomText>
                      <CustomText size={14 / 2} color="#9E9E9E">
                        {c?.docType || "-"}
                      </CustomText>
                    </View>
                    <View style={Styles.ctCellSecond}>
                      <CustomText size={16 / 2} color="#0a0a0a">
                        {c?.createdAt
                          ? moment(c?.createdAt)?.format("DD MMMM YYYY, HH:mm")
                          : "-"}
                      </CustomText>
                    </View>
                    <View style={Styles.ctCellThird}>
                      <CustomText size={16 / 2} color="#0a0a0a">
                        {`${c?.startDate
                            ? moment(c.startDate).format("ll")
                            : "no start date"
                          } - ${c?.endDate
                            ? moment(c?.endDate)?.format("ll")
                            : "no end date"
                          }`}
                      </CustomText>
                    </View>
                    <View style={Styles.ctCellFourth}>
                      {c?.file ? (
                        <Link
                          style={[{ color: "#1571DE", fontSize: 16 / 2 }]}
                          src={fileBaseUrl + c?.file}
                        >
                          Lihat File
                        </Link>
                      ) : (
                        <CustomText size={16 / 2} color="#0a0a0a">
                          no file
                        </CustomText>
                      )}
                    </View>
                    <View style={Styles.ctCellFifth}>
                      <View
                        style={[
                          Styles.status,
                          c?.status === "Active"
                            ? Styles.approved
                            : Styles.waiting,
                        ]}
                      >
                        <CustomText
                          size={16 / 2}
                          color={c?.status === "Active" ? "#27BB83" : "#EE962F"}
                        >
                          {c?.status === "Active" ? "Approved" : "Waiting"}
                        </CustomText>
                      </View>
                    </View>
                  </View>
                ))
              ) : (
                <View style={[Styles.ctRow, Styles.last, Styles.empty]}>
                  <Image
                    src={Images.FILE_AND_FOLDER}
                    style={{ height: 59, width: 86 }}
                  />
                  <CustomText size={16 / 2}>
                    Daftar Dokumen legal masih kosong
                  </CustomText>
                </View>
              )}
            </View>
          </View>
        </View>

        {/* <--------- Tax & BPJS --------> */}
        <View style={[Styles.table, Styles.radius]} wrap={false}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F3F9FF",
              paddingTop: 10 / 2,
              paddingBottom: 10 / 2,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <CustomText size={22 / 2} color="#000" type="bold">
              Pajak & BPJS
            </CustomText>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Pajak
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={Styles.content}>
                <CustomText size={14 / 2} color="#9e9e9e">
                  PPH21
                </CustomText>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives?.PPH21 || "-"}
                </CustomText>
              </View>
              <View style={Styles.content}>
                <CustomText size={14 / 2} color="#9e9e9e">
                  PPH23
                </CustomText>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives?.PPH23 || "-"}
                </CustomText>
              </View>
              <View style={Styles.content}>
                <CustomText size={14 / 2} color="#9e9e9e">
                  PPN
                </CustomText>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives?.PPN || "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                BPJS
              </CustomText>
            </View>
            {
              noBPJS
                ? <View style={Styles.contentWrapper}>
                  <View style={[Styles.content, { width: "100%" }]}>
                    <CustomText size={16 / 2} color="#0a0a0a">
                      FLB ini tidak menggunakan skema BPJS
                    </CustomText>
                  </View>
                </View>
                :
                <View style={Styles.contentWrapper}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 16 / 2,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 8 / 2,
                      }}
                    >
                      <View style={Styles.content}>
                        <View style={Styles.cellContentWrapper}>
                          <CustomText size={14 / 2} color="#9e9e9e">
                            Pendaftaran BPJS
                          </CustomText>
                          <CustomText size={16 / 2} color="#0a0a0a">
                            {workOrder?.orderDetail?.orderComprehensives
                              ?.PendaftaranBPJS || "-"}
                          </CustomText>
                        </View>
                      </View>
                      <View style={Styles.content}>
                        <View style={Styles.cellContentWrapper}>
                          <CustomText size={14 / 2} color="#9e9e9e">
                            Bukti pembayaran BPJS
                          </CustomText>
                          <CustomText size={16 / 2} color="#0a0a0a">
                            {workOrder?.orderDetail?.orderComprehensives
                              ?.BuktiPendaftaranBPJS || "-"}
                          </CustomText>
                        </View>
                      </View>
                    </View>
                    <View style={Styles.content}>
                      <View style={Styles.cellContentWrapper}>
                        <CustomText size={14 / 2} color="#9e9e9e">
                          Perhitungan BPJS TKO
                        </CustomText>
                        <CustomText size={16 / 2} color="#0a0a0a">
                          {!isNaN(
                            +workOrder?.orderDetail?.orderComprehensives
                              ?.PotonganBPJSTKO
                          )
                            ? "Manual"
                            : workOrder?.orderDetail?.orderComprehensives
                              ?.PotonganBPJSTKO || "-"}
                        </CustomText>
                      </View>
                    </View>
                    {!isNaN(
                      +workOrder?.orderDetail?.orderComprehensives?.PotonganBPJSTKO
                    ) && (
                        <View style={Styles.content}>
                          <View style={Styles.cellContentWrapper}>
                            <CustomText size={14 / 2} color="#9e9e9e">
                              Angka Perhitungan
                            </CustomText>
                            <CustomText size={16 / 2} color="#0a0a0a">
                              {`Rp${IDR.format(
                                +workOrder?.orderDetail?.orderComprehensives
                                  ?.PotonganBPJSTKO
                              )}`}
                            </CustomText>
                          </View>
                        </View>
                      )}
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8 / 2,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          paddingBottom: 8 / 2,
                          paddingTop: 8 / 2,
                        }}
                      >
                        <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                          {workOrder?.orderDetail?.orderComprehensives
                            ?.PenagihanBPU === ""
                            ? "Penerima Upah"
                            : "Bukan Penerima Upah"}
                        </CustomText>
                      </View>

                      {workOrder?.orderDetail?.orderComprehensives?.PenagihanBPU ===
                        "" ? (
                        <View>
                          {" "}
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 8 / 2,
                            }}
                          >
                            <View
                              style={[Styles.cellContentWrapper, { width: "50%" }]}
                            >
                              <CustomText size={14 / 2} color="#9e9e9e">
                                JKK (Jaminan Kelengkapan Kerja)
                              </CustomText>
                              <CustomText size={16 / 2} color="#0a0a0a">
                                {workOrder?.orderDetail?.orderComprehensives
                                  ?.JKK !== "null" &&
                                  workOrder?.orderDetail?.orderComprehensives?.JKK
                                  ? workOrder?.orderDetail?.orderComprehensives?.JKK
                                  : "-"}
                              </CustomText>
                            </View>
                            <View
                              style={[Styles.cellContentWrapper, { width: "50%" }]}
                            >
                              <CustomText size={14 / 2} color="#9e9e9e">
                                JHT (Jaminan Hari Tua)
                              </CustomText>
                              <CustomText size={16 / 2} color="#0a0a0a">
                                {workOrder?.orderDetail?.orderComprehensives
                                  ?.JHT !== "null" &&
                                  workOrder?.orderDetail?.orderComprehensives?.JHT
                                  ? workOrder?.orderDetail?.orderComprehensives?.JHT
                                  : "-"}
                              </CustomText>
                            </View>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 8 / 2,
                            }}
                          >
                            <View
                              style={[Styles.cellContentWrapper, { width: "50%" }]}
                            >
                              <CustomText size={14 / 2} color="#9e9e9e">
                                JKM (Jaminan Kematian)
                              </CustomText>
                              <CustomText size={16 / 2} color="#0a0a0a">
                                {workOrder?.orderDetail?.orderComprehensives
                                  ?.JKM !== "null" &&
                                  workOrder?.orderDetail?.orderComprehensives?.JKM
                                  ? workOrder?.orderDetail?.orderComprehensives?.JKM
                                  : "-"}
                              </CustomText>
                            </View>
                            <View
                              style={[Styles.cellContentWrapper, { width: "50%" }]}
                            >
                              <CustomText size={14 / 2} color="#9e9e9e">
                                JP (Jaminan Pensiun)
                              </CustomText>
                              <CustomText size={16 / 2} color="#0a0a0a">
                                {workOrder?.orderDetail?.orderComprehensives?.JP !==
                                  "null" &&
                                  workOrder?.orderDetail?.orderComprehensives?.JP
                                  ? workOrder?.orderDetail?.orderComprehensives?.JP
                                  : "-"}
                              </CustomText>
                            </View>
                          </View>
                        </View>
                      ) : (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 8 / 2,
                          }}
                        >
                          <View
                            style={[Styles.cellContentWrapper, { width: "50%" }]}
                          >
                            <CustomText size={14 / 2} color="#9e9e9e">
                              Penagihan BPJS
                            </CustomText>
                            <CustomText size={16 / 2} color="#0a0a0a">
                              {workOrder?.orderDetail?.orderComprehensives
                                ?.PenagihanBPU === "Tagih Ke Perusahaan"
                                ? "Penagihan BPJS akan ditagihkan kepada perusahaan"
                                : "Penagihan BPJS akan ditagihkan kepada karyawan"}
                            </CustomText>
                          </View>
                          <View
                            style={[Styles.cellContentWrapper, { width: "50%" }]}
                          >
                            <CustomText size={14 / 2} color="#9e9e9e">
                              JP (Jaminan Pensiun)
                            </CustomText>
                            <CustomText size={16 / 2} color="#0a0a0a">
                              {workOrder?.orderDetail?.orderComprehensives?.JP !==
                                "null" &&
                                workOrder?.orderDetail?.orderComprehensives?.JP
                                ? workOrder?.orderDetail?.orderComprehensives?.JP
                                : "-"}
                            </CustomText>
                          </View>
                        </View>
                      )}
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8 / 2,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          paddingBottom: 8 / 2,
                          paddingTop: 16 / 2,
                        }}
                      >
                        <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                          Kesehatan
                        </CustomText>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View style={[Styles.cellContentWrapper, { width: "50%" }]}>
                          <CustomText size={14 / 2} color="#9e9e9e">
                            KS (Kesehatan)
                          </CustomText>
                          <CustomText size={16 / 2} color="#0a0a0a">
                            {workOrder?.orderDetail?.orderComprehensives?.KS !==
                              "null" &&
                              workOrder?.orderDetail?.orderComprehensives?.KS
                              ? workOrder?.orderDetail?.orderComprehensives?.KS
                              : "-"}
                          </CustomText>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8 / 2,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          paddingBottom: 8 / 2,
                          paddingTop: 16 / 2,
                        }}
                      >
                        <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                          AKDHK
                        </CustomText>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View style={[Styles.cellContentWrapper, { width: "50%" }]}>
                          <CustomText size={14 / 2} color="#9e9e9e">
                            Asuransi Kecelakaan Diluar Hari Kerja
                          </CustomText>
                          <CustomText size={16 / 2} color="#0a0a0a">
                            {workOrder?.orderDetail?.orderComprehensives?.AKDHK !==
                              "null" &&
                              workOrder?.orderDetail?.orderComprehensives?.AKDHK
                              ? workOrder?.orderDetail?.orderComprehensives?.AKDHK
                              : "-"}
                          </CustomText>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8 / 2,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          paddingBottom: 8 / 2,
                          paddingTop: 16 / 2,
                        }}
                      >
                        <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                          Remark
                        </CustomText>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View style={[Styles.cellContentWrapper, { width: "50%" }]}>
                          <CustomText size={16 / 2} color="#0a0a0a">
                            {workOrder?.orderDetail?.orderComprehensives?.remark ||
                              "-"}
                          </CustomText>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
            }

          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Pengenaan Manfee
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={[Styles.content, { width: "100%" }]}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives?.PengenaanManfee?.replace(
                    /::/g,
                    ", "
                  ) || "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Penagihan THR
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={[Styles.content, { width: "100%" }]}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives?.PenagihanTHR ||
                    "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={Styles.row}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Penagihan UAK
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={[Styles.content, { width: "100%" }]}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives?.PenagihanUAK ||
                    "-"}
                </CustomText>
              </View>
            </View>
          </View>
          <View style={[Styles.row, { borderBottomWidth: 0 }]}>
            <View style={Styles.columnLeft}>
              <CustomText size={18 / 2} color="#0a0a0a" type="bold">
                Rekening Wajib TKO
              </CustomText>
            </View>
            <View style={Styles.contentWrapper}>
              <View style={[Styles.content, { width: "100%" }]}>
                <CustomText size={16 / 2} color="#0a0a0a">
                  {workOrder?.orderDetail?.orderComprehensives?.RekeningWajibTKO?.replace(
                    /::/g,
                    ", "
                  ) || "-"}
                </CustomText>
              </View>
            </View>
          </View>
        </View>

        {/* <--------- Deployment & Position --------> */}
        <View style={[Styles.table, Styles.radius]} break>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F3F9FF",
              paddingTop: 10 / 2,
              paddingBottom: 10 / 2,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <CustomText size={22 / 2} color="#000" type="bold">
              Deployment & Position
            </CustomText>
          </View>
          <View style={Styles.dpContentWrapper}>
            <View style={[Styles.dpSection]}>
              <CustomText color={"#1571DE"} size={22 / 2} type={"bold"}>
                Deployment
              </CustomText>
              <View style={[Styles.dpContent]}>
                {workOrder?.delegations?.map((d, dI) => (
                  <View key={dI} style={[Styles.dpCard, Styles.radius]}>
                    <View style={Styles.dpcHeader}>
                      <Image
                        style={Styles.dpcLogo}
                        src={Images.COMPANY_OUTLINE}
                      />
                      <CustomText size={18 / 2} type={"bold"}>
                        {d?.cityName}
                      </CustomText>
                    </View>
                    <View style={Styles.dpcContent}>
                      {[
                        {
                          title: "Business Manager",
                          users: d?.users?.businessManagers,
                        },
                        {
                          title: "Account Officer/Account Manager",
                          users: d?.users?.accountManagerOrPIC,
                        },
                        {
                          title: "Admin/Admin Contract",
                          users: d?.users?.accountOfficerOrAdmin,
                        },
                        {
                          title: "Recruitment Officer",
                          users: d?.users?.recruitmentOfficers,
                        },
                      ]?.map((r, rI) => (
                        <View key={rI} style={[Styles.dpcRole, Styles.radius]}>
                          <CustomText size={18 / 2} type={"bold"}>
                            {r?.title}
                          </CustomText>
                          {r?.users?.length ? (
                            <View style={Styles.dpcUsers}>
                              {r?.users?.map((u, uI) => (
                                <View
                                  key={uI}
                                  style={[Styles.dpcUser, Styles.radius]}
                                >
                                  <Image
                                    src={Images.AVA_DEFAULT}
                                    style={[
                                      {
                                        width: 36 / 2,
                                        aspectRatio: 1,
                                        borderRadius: "50%",
                                      },
                                    ]}
                                  />
                                  <View>
                                    <CustomText size={15 / 2}>
                                      {u?.User?.name}
                                    </CustomText>
                                    <CustomText size={13 / 2} color={"#9E9E9E"}>
                                      {`${r?.title} ${r?.users?.length > 1 ? uI + 1 : ""
                                        }`}
                                    </CustomText>
                                  </View>
                                </View>
                              ))}
                            </View>
                          ) : (
                            <View style={[Styles.empty]}>
                              <Image
                                src={Images.FILE_AND_FOLDER}
                                style={{ height: 59, width: 86 }}
                              />
                              <CustomText size={16 / 2}>
                                Informasi delegasi masih kosong
                              </CustomText>
                            </View>
                          )}
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <View style={[Styles.dpSection]}>
              <CustomText color={"#1571DE"} size={22 / 2} type={"bold"}>
                Position
              </CustomText>
              <View style={[Styles.dpContent]}>
                {workOrder?.manPowers?.map((m, mI) => (
                  <View key={mI} style={[Styles.dppCard, Styles.radius]}>
                    <View style={Styles.dppHeader}>
                      <View style={Styles.dppHLeft}>
                        <Image
                          style={[{ width: 16, aspectRatio: 1 }]}
                          src={Images.BRIEFCASE_OUTLINE}
                        />
                        <CustomText
                          type={"bold"}
                          size={21 / 2}
                          color={"#1571DE"}
                        >
                          {m?.position}
                        </CustomText>
                      </View>
                      <View style={Styles.dppHRight}>
                        <CustomText size={18 / 2}>
                          Recruitment Officer Dedicated :
                        </CustomText>
                        <View style={Styles.childdppHRight}>
                          <Image
                            src={Images.AVA_DEFAULT}
                            style={{ width: 28 / 2 }}
                          />
                          <CustomText size={18 / 2} type={"bold"}>
                            {m?.dedicatedRO?.name}
                          </CustomText>
                        </View>
                      </View>
                    </View>
                    <View style={Styles.dppContent}>
                      <View style={[Styles.dppSection, Styles.qualifications]}>
                        <CustomText size={18 / 2} type="bold">
                          Job Qualification
                        </CustomText>
                        <View style={[Styles.qTable, Styles.radius]}>
                          <View style={Styles.qtHeader}>
                            <View style={Styles.qtCollFirst}>
                              <CustomText
                                size={16 / 2}
                                color={"#000"}
                                fontWeight={"500"}
                              >
                                Title
                              </CustomText>
                            </View>
                            <View style={Styles.qtCollSecond}>
                              <CustomText
                                size={16 / 2}
                                color={"#000"}
                                fontWeight={"500"}
                              >
                                Qualification
                              </CustomText>
                            </View>
                          </View>
                          <View style={Styles.qtBody}>
                            {m?.qualification?.map((q, qI) => (
                              <View key={qI} style={Styles.qtRow}>
                                <View style={Styles.qtCollFirst}>
                                  <CustomText
                                    size={16 / 2}
                                    color={"#000"}
                                    fontWeight={"400"}
                                  >
                                    {q?.name}
                                  </CustomText>
                                </View>
                                <View style={Styles.qtCollSecond}>
                                  <CustomText
                                    size={16 / 2}
                                    color={"#000"}
                                    fontWeight={"500"}
                                  >
                                    {q?.value}
                                  </CustomText>
                                </View>
                              </View>
                            ))}
                          </View>
                        </View>
                      </View>
                      <View style={[Styles.dppSection, Styles.qualifications]}>
                        <CustomText size={18 / 2} type="bold">
                          City
                        </CustomText>
                        <View style={[Styles.allCity, Styles.radius]}>
                          {m?.cities?.map((c, cI) => (
                            <View key={cI} style={Styles.perCity} wrap={false}>
                              <View style={Styles.pcHeader}>
                                <Image
                                  src={Images.HALF_EYE}
                                  style={[{ width: 19 / 2 }]}
                                />
                                <CustomText type={"bold"} size={16 / 2}>
                                  {c?.cityName}
                                </CustomText>
                              </View>
                              <View style={Styles.pcIndicators}>
                                {[
                                  {
                                    title: "Total",
                                    value: c?.position?.amount,
                                  },
                                  {
                                    title: "Salary",
                                    value: `${IDR.format(
                                      c?.position?.salary?.split("::")[0]
                                    )} • ${c?.position?.salary?.split("::")[0]
                                      }`,
                                  },
                                  {
                                    title: "Expected date",
                                    value: moment(
                                      c?.position?.expectedFulfillmentDate
                                    )?.format("LL"),
                                  },
                                ]?.map((n, nI) => (
                                  <View key={nI} style={Styles.indicator}>
                                    <CustomText size={12 / 2} color={"#9E9E9E"}>
                                      {n?.title}
                                    </CustomText>
                                    <CustomText size={14 / 2}>
                                      {n?.value}
                                    </CustomText>
                                  </View>
                                ))}
                              </View>
                              <View style={Styles.pcDetails}>
                                {[
                                  {
                                    title: "Allowance",
                                    list: c?.position?.allowances?.map(
                                      (obj) => {
                                        return {
                                          name: obj?.name,
                                          value: obj?.value
                                            ? `${IDR.format(obj?.value)}`
                                            : "-",
                                          denom: obj?.denom || "Bulanan",
                                        };
                                      }
                                    ),
                                  },
                                  {
                                    title: "Personal Work Equipment",
                                    list: c?.position?.personalEquipments?.map(
                                      (obj) => {
                                        return {
                                          name: obj?.name,
                                          value: obj?.price
                                            ? `${IDR.format(obj?.price)}`
                                            : "-",
                                        };
                                      }
                                    ),
                                  },
                                ]?.map((d, dI) => (
                                  <View
                                    key={dI}
                                    style={[Styles.pcdCard, Styles.radius]}
                                  >
                                    <CustomText size={15 / 2} type="bold">
                                      {d?.title}
                                    </CustomText>
                                    {d?.list?.length ? (
                                      <View style={Styles.pcdList}>
                                        {d?.list?.map((l, lI) => (
                                          <View
                                            key={lI}
                                            style={Styles.pcdPoint}
                                          >
                                            <CustomText size={14 / 2}>
                                              {l?.name}
                                            </CustomText>
                                            <CustomText
                                              size={14 / 2}
                                              type={"bold"}
                                            >
                                              {l?.value}{" "}
                                              {l?.denom
                                                ? `/ ${l?.denom}`
                                                : null}
                                            </CustomText>
                                          </View>
                                        ))}
                                      </View>
                                    ) : (
                                      <View style={[Styles.empty]}>
                                        <Image
                                          src={Images.FILE_AND_FOLDER}
                                          style={{ height: 59, width: 86 }}
                                        />
                                        <CustomText size={16 / 2}>
                                          Data masih kosong
                                        </CustomText>
                                      </View>
                                    )}
                                    <View></View>
                                  </View>
                                ))}
                              </View>
                            </View>
                          ))}
                        </View>
                        <CustomText size={18 / 2} type="bold">
                          Remark
                        </CustomText>
                        <View style={[Styles.remarkBox, Styles.radius]}>
                          <CustomText size={14 / 2}>
                            {workOrder?.orderDetail?.remark || "-"}
                          </CustomText>
                        </View>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>

        {/* <----- Cost Estimation -----> */}
        <View style={[Styles.table, Styles.radius]} break>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F3F9FF",
              paddingTop: 10 / 2,
              paddingBottom: 10 / 2,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <CustomText size={22 / 2} color="#000" type="bold">
              Cost Estimation
            </CustomText>
          </View>
          {workOrder?.costEstimation?.areaCosts?.map((data, id) => (
            <CostEstimation
              key={id}
              data={data}
              manfee={workOrder?.orderDetail?.manfee}
              orderComprehensives={workOrder?.orderDetail?.orderComprehensives}
              lastId={id === workOrder?.costEstimation?.areaCosts?.length - 1}
            />
          ))}
        </View>
        <TermOfPayment top={workOrder?.orderDetail?.termOfPayment} />
        <View style={Styles.footerWrapper} fixed>
          <View style={Styles.footer}>
            <View style={Styles.footerSection}>
              <Image source={GLOBE} style={{ height: 10, width: 10 }} />
              <CustomText size={16 / 2} color="#000">
                www.permataindonesia.com
              </CustomText>
            </View>
            <View style={Styles.footerSection}>
              <Image source={TELEPHONE} style={{ height: 10, width: 10 }} />
              <CustomText size={16 / 2} color="#000">
                021 726 5364
              </CustomText>
            </View>
            {/* <View style={Styles.footerSection}>
                <Image source={EMAIL} style={{ height: 10, width: 10 }} />
                <CustomText size={16 / 2} color="#000">
                  021 726 5364
                </CustomText>
              </View> */}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Doc;
