import React, { useState, useRef, useEffect, useCallback } from "react";
import Styles from "./dropdown.module.scss";
import Icon from "@Atom/Icon";
import ErrorBubble from "@Atom/ErrorBubble";

const Dropdown = ({
  placeholder,
  disabled,
  options = [],
  value,
  setValue,
  autoClose,
  fixedHeight,
  iconColor,
  withDefaultSetter,
  defaultText,
  handleSetDefault,
  withIcon = false,
  iconName,
  withReset = false,
  resetText = "Reset",
  disabledNull = true,
  isError = false,
  isErrorV2 = false,
  errorText = "",
  onClickOption = () => {},
  top = false,
  overFlow = false,
  ...props
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const activateDropdown = () => {
    if (!disabled) {
      setIsActive(!isActive);
    }
  };

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    },
    [ref, isActive]
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  useEffect(() => {
    if (disabled && disabledNull) {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, disabledNull]);

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        className={`${Styles.container} ${
          isActive ? Styles.activeContainer : null
        }
        ${disabled ? Styles.containerColorDisabled : null}
        ${isError ? Styles.error : ""}`}
        onClick={activateDropdown}
        style={{ height: fixedHeight || "auto" }}
      >
        {withIcon && (
          <div className={Styles.iconWrapper}>
            <Icon icon={iconName} size="20px" color={iconColor || "#0a0a0a"} />
          </div>
        )}
        <div
          className={`${value ? Styles.valueText : Styles.placeholderText} ${
            disabled ? Styles.disabled : ""
          }`}
        >
          {disabled ? value || "Select" : value ? value : placeholder}
        </div>
        {isActive ? (
          <Icon icon="arrow-up" size="20px" color={iconColor || "#0a0a0a"} />
        ) : (
          <Icon icon="arrow-down" size="20px" color={iconColor || "#0a0a0a"} />
        )}
      </div>
      {isActive && !disabled ? (
        <div
          className={`${Styles.options} ${top && Styles.topOptions} ${
            overFlow && Styles.overFlowOptions
          }`}
        >
          {withDefaultSetter && (
            <div className={Styles.optionWrapper}>
              <div
                className={`${Styles.option} ${Styles.default}`}
                onClick={() => {
                  if (autoClose) {
                    handleSetDefault();
                    setIsActive(false);
                  } else {
                    handleSetDefault();
                  }
                }}
              >
                {defaultText}
              </div>
              <div className={Styles.borderBottom}></div>
            </div>
          )}
          {withReset && (
            <div className={Styles.optionWrapper}>
              <div
                className={`${Styles.option} ${Styles.reset}`}
                onClick={() => {
                  if (autoClose) {
                    setValue("");
                    setIsActive(false);
                  } else {
                    setValue("");
                  }
                }}
              >
                {resetText}
              </div>
              <div className={Styles.borderBottom}></div>
            </div>
          )}
          {options.map((option, id) => {
            return (
              <div className={`${Styles.optionWrapper}`} key={id}>
                <div
                  className={Styles.option}
                  onClick={() => {
                    if (autoClose) {
                      setValue(option.name);
                      onClickOption(option);
                      setIsActive(false);
                    } else {
                      setValue(option.name);
                      onClickOption(option);
                    }
                  }}
                >
                  {option?.name}
                </div>
                {id === options.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      {isError &&
        !disabled &&
        (!isErrorV2 ? (
          <span className={Styles.errorText}>{errorText}</span>
        ) : (
          <ErrorBubble errorText={errorText} />
        ))}
    </div>
  );
};

export default Dropdown;
