import AutocompleteV2 from '@Atom/AutocompleteV2'
import CheckboxJobPosting from '@Atom/CheckboxJobPosting'
import Icon from '@Atom/Icon'
import ModalWrapper from '@Atom/ModalWrapper'
import { makeRandomString } from '@Helpers/makeRandomString'
import InputField, { InputTitle } from '@Molecule/InputField'
import Images from '@Theme/Images'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function NewAddPositionModal({
  show = false,
  setShow = () => { },
  onAdd = () => { },
  existingPositionNames = [],
  editMode = false,
  defaultPositionName = '',
  editQtyMode = false,
  defaultQty,
  generalDedicatedRO = null,
  setGeneralDedicatedRO = () => { },
  delegationsForm = [],
  alertMessage = '',
  qualificationOptions = [],
  defaultPositionData = null
}) {
  const [step, setStep] = useState(1)
  const [positionName, setPositionName] = useState(defaultPositionData?.positionName || defaultQty || '')
  const [dedicatedROId, setDedicatedROId] = useState(defaultPositionData?.dedicatedROId || generalDedicatedRO?.id || '')
  const [dedicatedROName, setDedicaatedROName] = useState(defaultPositionData?.dedicatedROName || generalDedicatedRO?.name || '')
  const [qualifications, setQualifications] = useState(
    defaultPositionData?.qualifications?.length
      ? defaultPositionData?.qualifications
      :
      [
        {
          id: 1,
          name: '',
          value: ''
        }
      ])

  const [isGeneralRO, setIsGeneralRO] = useState(generalDedicatedRO?.id === dedicatedROId ? true : false)

  const sortedQualificationOptions = useMemo(() => {
    const lainnya = qualificationOptions?.find(obj => obj?.name === 'Lainnya')
    return lainnya ? [...qualificationOptions?.filter(obj => obj?.name !== 'Lainnya')?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)), lainnya] : qualificationOptions?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }, [qualificationOptions])


  const roList = useMemo(() => {
    return delegationsForm
      ?.map((obj) => obj?.deployments[3]?.delegations)
      ?.flat()
      ?.filter((u) => u?.isPicked)
      ?.filter(
        (value, index, self) =>
          index === self?.findIndex((t) => t?.id === value?.id)
      )
  }, [delegationsForm])

  useEffect(() => {
    setStep(1)
  }, [show])

  const disableSave = useMemo(() => {
    if (editQtyMode) {
      return !positionName
    } else {
      if (!editMode) {
        return existingPositionNames?.find(obj => obj?.toUpperCase() === positionName?.toUpperCase())
      } else {
        // eslint-disable-next-line eqeqeq
        if (step == 1) {
          return !positionName || !dedicatedROId
        } else {
          return !qualifications?.length || qualifications?.find(obj => !obj?.value || !obj?.name)
        }
      }

    }
  }, [dedicatedROId, editMode, editQtyMode, existingPositionNames, positionName, qualifications, step])

  console.log(defaultPositionData, 'dpppp')


  return (
    <ModalWrapper show={show} handleClose={() => setShow(false)}>
      <div className={`${Styles.container} ${Styles[!editQtyMode ? 'wide' : 'small']}`}>
        <div className={Styles.header}>
          <img src={Images.ADD_POSITION_LOGO} alt='' />
          <h3>{editQtyMode ? 'Edit Jumlah Pemenuhan' : `${editMode ? 'Edit' : 'Tambah'} Posisi`}</h3>
        </div>
        {
          editQtyMode
            ?
            <div className={Styles.content}>
              <InputField
                title={editQtyMode ? 'Jumlah' : 'Position'}
                value={positionName}
                placeholder={editQtyMode ? 'Minimal 1' : 'Enter position'}
                onChange={(e) => setPositionName(e?.target?.value?.toUpperCase())}
                type={editQtyMode ? 'number' : ''}
                onKeyDown={editQtyMode ? (evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault() : () => { }}
              />
              <div className={Styles.actions}>
                <button
                  onClick={() => setShow(false)}
                >Cancel</button>
                <button
                  disabled={disableSave}
                  onClick={() => {
                    onAdd(positionName)
                    setShow(false)
                  }}
                >Save</button>
              </div>

            </div>
            :
            <div className={Styles.newContent}>
              <div className={Styles.ncHeader}>
                <button disabled={step === 1} onClick={() => setStep(1)}>
                  <Icon icon={'arrow-left-back'} size={20} />
                  <span>Kembali</span>
                </button>
                <span>Step {step}/2</span>
              </div>
              {/* <div className={Styles.ncForm}> */}
              {
                step === 1
                  ?
                  <div className={Styles.first}>
                    <InputField
                      title={'Nama Posisi'}
                      value={positionName}
                      placeholder={'Masukkan nama posisi'}
                      onChange={(e) => setPositionName(e?.target?.value?.toUpperCase())}
                      disabled={defaultPositionData?.preventDelete}
                    />
                    <div className={Styles.field}>
                      <InputTitle title={'RO Dedicated'} />
                      <AutocompleteV2
                        value={dedicatedROName}
                        placeholder="Select"
                        onClickOption={(option) => {
                          if (dedicatedROId !== option?.id) {
                            setDedicaatedROName(option?.name);
                            // setPositionData('dedicatedROId', option?.id)
                            setDedicatedROId(option?.id);
                          }
                        }}
                        options={roList}
                        // isError={
                        //   (alertMessage?.includes(positionName) &&
                        //     alertMessage?.includes("Dedicated")) ||
                        //   !roList?.length
                        // }
                        forceValue
                      />
                      <div className={Styles.generalRO}>
                        <CheckboxJobPosting
                          checked={isGeneralRO}
                          onChange={() => setIsGeneralRO(!isGeneralRO)}
                        />
                        <span
                          onClick={() => setIsGeneralRO(!isGeneralRO)}
                        >Jadikan RO ini sebagai RO Dedicated untuk semua posisi</span>
                      </div>
                    </div>

                  </div>
                  :
                  <div className={Styles.second}>
                    <span>Kualifikasi{positionName ? ` - ${positionName}` : ''}</span>
                    <div className={Styles.qWrapper}>
                      {
                        qualifications?.map((q, qI) => (
                          <div key={qI} className={Styles.eachQ}>
                            <div className={Styles.searchQ}>
                              <InputTitle title={qI === 0 ? 'Kategori Kualifikasi' : ''} />
                              <AutocompleteV2
                                value={q?.name}
                                setValue={(newVal) => {
                                  setQualifications(qualifications?.map((obj, i) => qI === i ? {
                                    ...obj,
                                    name: newVal
                                  } : obj))
                                }}
                                placeholder="Cari kualifikasi"
                                onClickOption={(option) => {
                                  setQualifications(qualifications?.map((obj, i) => qI === i ? {
                                    ...obj,
                                    name: option?.name
                                  } : obj))
                                }}
                                options={qualificationOptions?.find(obj => obj?.name?.toLowerCase()?.includes(q?.name?.toLowerCase())) ? [qualificationOptions?.find(obj => obj?.name?.toLowerCase()?.includes(q?.name?.toLowerCase())), ...sortedQualificationOptions?.filter((obj) =>
                                  qualifications?.every((f) => f?.name !== obj?.name)
                                )]?.filter((value, index, self) =>
                                  index === self.findIndex((t) => (
                                    t.name === value.name
                                  ))
                                ) : sortedQualificationOptions?.filter((obj) =>
                                  qualifications?.every((f) => f?.name !== obj?.name)
                                )}
                              // isError={
                              //   (alertMessage?.includes(positionName) &&
                              //     alertMessage?.includes("Dedicated")) ||
                              //   !roList?.length
                              // }
                              />
                            </div>
                            <InputField
                              title={qI === 0 ? 'Detail Kualifikasi' : ''}
                              value={q?.value}
                              placeholder={'Detail kualifikasi'}
                              onChange={(e) => setQualifications(qualifications?.map((obj, i) => obj?.name === q?.name ? {
                                ...obj,
                                value: e?.target?.value
                              } : obj))}
                            />
                            <div className={Styles.bWrapper}>
                              <button disabled={qI === 0} onClick={() => setQualifications(qualifications?.filter(obj => obj?.id !== q?.id))}>
                                <Icon icon={'trash'} size={24} />
                              </button>
                            </div>
                          </div>
                        ))
                      }

                    </div>
                    <button onClick={() => setQualifications([...qualifications, {
                      id: makeRandomString(5),
                      name: '',
                      value: ''
                    }])}>
                      <span>Tambah Kualifikasi</span>
                      <Icon icon={'page-plus'} size={20} />
                    </button>
                  </div>
              }
              {/* </div> */}
              <div className={Styles.actions}>
                <button
                  onClick={() => setShow(false)}
                >Cancel</button>
                <button
                  disabled={disableSave}
                  onClick={() => {
                    if (editQtyMode || step === 2) {
                      onAdd({ positionName, dedicatedROId, dedicatedROName, qualifications })
                      if (isGeneralRO) {
                        setGeneralDedicatedRO({
                          id: dedicatedROId,
                          name: dedicatedROName
                        })
                      } else {
                        setGeneralDedicatedRO(null)
                      }
                      setShow(false)
                    } else {
                      setStep(2)
                    }
                  }}
                >{step === 1 ? 'Lanjut' : 'Simpan'}</button>
              </div>
            </div>

        }

      </div>
    </ModalWrapper>
  )
}