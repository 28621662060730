/* eslint-disable no-unused-vars */
import SelectOptionsToggle from "@Molecule/SelectOptionToggle";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { InputTitle } from "@Molecule/InputField";
import ErrorBubble from "@Atom/ErrorBubble";
import DinamicDecorativeInputField from "@Molecule/DinamicDecorativeInputField";
import Switch from "@Atom/Switch";

export default function BPJSSection(props) {
  const {
    form,
    setForm,
    options,
    setTrigger,
    selectedButtonBPJS,
    setActiveButtonBPJS,
    setSelectedButtonBPJS,
    selectedValueBPU,
    setSelectedValueBPU,
    alertMessage = '',
    withBPJS = false,
    setWithBPJS = () => { },
    optionalBPJS = false,
  } = props;

  const [registration, setRegistration] = useState(form?.bpjs?.registration);
  useEffect(() => {
    setRegistration(form?.bpjs?.registration)
  }, [form?.bpjs?.registration, registration])

  const [paymentProof, setPaymentProof] = useState(form?.bpjs?.paymentProof);
  useEffect(() => {
    setPaymentProof(form?.bpjs?.paymentProof)
  }, [form?.bpjs?.paymentProof])

  const [jkkOn, setJkkOn] = useState(form?.bpjs?.JKK ? true : form?.bpjs?.jkkOn);
  // useEffect(() => {
  //   setJkkOn(form?.bpjs?.jkkOn)
  // }, [form?.bpjs?.jkkOn])

  const [jkk, setJkk] = useState(form?.bpjs?.jkk);
  useEffect(() => {
    setJkk(form?.bpjs?.jkk)
  }, [form?.bpjs?.jkk])

  const [jkmOn, setJkmOn] = useState(form?.bpjs?.jkmOn);
  // useEffect(() => {
  //   setJkmOn(form?.bpjs?.jkmOn)
  // }, [form?.bpjs?.jkmOn])

  const [jkm, setJkm] = useState(form?.bpjs?.jkm);
  useEffect(() => {
    setJkm(form?.bpjs?.jkm)
  }, [form?.bpjs?.jkm])


  const [jhtOn, setJhtOn] = useState(form?.bpjs?.jhtOn);
  // useEffect(() => {
  //   setJhtOn(form?.bpjs?.jhtOn)
  // }, [form?.bpjs?.jhtOn])

  const [jht, setJht] = useState(form?.bpjs?.jht);
  useEffect(() => {
    setJht(form?.bpjs?.jht)
  }, [form?.bpjs?.jht])

  console.log(jht, 'ini jht')

  const [jpOn, setJpOn] = useState(form?.bpjs?.jpOn);
  // useEffect(() => {
  //   setJpOn(form?.bpjs?.jpOn)
  // }, [form?.bpjs?.jpOn])

  const [jp, setJp] = useState(form?.bpjs?.jp);
  useEffect(() => {
    setJp(form?.bpjs?.jp)
  }, [form?.bpjs?.jp])

  const [ks, setKs] = useState(form?.bpjs?.health);
  useEffect(() => {
    setKs(form?.bpjs?.health)
  }, [form?.bpjs?.health])

  const [ksOn, setKsOn] = useState(form?.bpjs?.healthOn);
  // useEffect(() => {
  //   setKsOn(form?.bpjs?.healthOn)
  // }, [form?.bpjs?.healthOn])

  const [akdhkOn, setAkdhkOn] = useState(form?.bpjs?.akhdkOn);
  // useEffect(() => {
  //   setAkdhkOn(form?.bpjs?.akhdkOn)
  // }, [form?.bpjs?.akhdkOn])

  const [akdhk, setAkdhk] = useState(form?.bpjs?.akhdk);
  useEffect(() => {
    setAkdhk(form?.bpjs?.akhdk)
  }, [form?.bpjs?.akhdk])

  const [cutOffBPJSTKO, setCutOffBPJSTKO] = useState(form?.bpjs?.cutOffBPJSTKO);
  useEffect(() => {
    setCutOffBPJSTKO(form?.bpjs?.cutOffBPJSTKO)
  }, [form?.bpjs?.cutOffBPJSTKO])

  const [cutOffNominal, setCutOffNominal] = useState(form?.bpjs?.cutOffNominal || '');
  useEffect(() => {
    setCutOffNominal(form?.bpjs?.cutOffNominal)
  }, [form?.bpjs?.cutOffNominal])


  useEffect(() => {
    if (form?.tkoContract?.includes('PKWT')) {
      setActiveButtonBPJS('Tenaga Kerja')
    }
  }, [form?.tkoContract, setActiveButtonBPJS])

  useEffect(() => {
    const newForm = { ...form };
    newForm.bpjs.registration = registration;
    newForm.bpjs.paymentProof = paymentProof;
    // if(jkk !== newForm.bpjs.jkk){
    newForm.bpjs.jkk = jkk;
    // }
    newForm.bpjs.jkkOn = jkkOn;

    newForm.bpjs.jkm = jkm;
    newForm.bpjs.jkmOn = jkmOn;
    newForm.bpjs.jht = jht;
    newForm.bpjs.jhtOn = jhtOn;
    newForm.bpjs.jpOn = jpOn;
    newForm.bpjs.jp = jp;
    newForm.bpjs.health = ks;
    newForm.bpjs.healthOn = ksOn;

    newForm.bpjs.akhdkOn = akdhkOn;
    newForm.bpjs.akhdk = akdhk;
    newForm.bpjs.cutOffBPJSTKO = cutOffBPJSTKO;
    // eslint-disable-next-line eqeqeq
    if (cutOffBPJSTKO === 'Manual' && (newForm.bpjs.cutOffNominal != cutOffNominal)) {
      newForm.bpjs.cutOffNominal = cutOffNominal;
    }
    setForm(newForm);
    setTrigger(Math.random());
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [akdhk, akdhkOn, cutOffBPJSTKO, cutOffNominal, jht, jhtOn, jkk, jkkOn, jkm, jkmOn, jp, jpOn, ks, ksOn, paymentProof, registration, setForm, setTrigger]);

  // useEffect(() => {
  //   const newForm ={...form};
  //   // newForm.bpjs.registration = registration;
  //   // newForm.bpjs.paymentProof = paymentProof;
  //   // newForm.bpjs.jkk = jkk;
  //   // newForm.bpjs.jkkOn = jkkOn;
  //   // newForm.bpjs.jkm = jkm;
  //   // newForm.bpjs.jkmOn = jkmOn;
  //   // newForm.bpjs.jht = jht;
  //   // newForm.bpjs.jhtOn = jhtOn;
  //   // newForm.bpjs.jpOn = jpOn;
  //   // newForm.bpjs.jp = jp;
  //   // newForm.bpjs.health = ks;
  //   // newForm.bpjs.healthOn = ksOn;
  //   // newForm.bpjs.akhdkOn = akdhkOn;
  //   // newForm.bpjs.akhdk = akdhk;
  //   newForm.bpjs.cutOffBPJSTKO = cutOffBPJSTKO;
  //   newForm.bpjs.cutOffNominal = cutOffNominal;
  //   setForm(newForm);
  //   setTrigger(Math.random());
  // // eslint-disable-next-line react-hooks/exhaustive-deps

  // }, [cutOffBPJSTKO, cutOffNominal, setForm, setTrigger]);

  const handleRadioChange = (event) => {
    setSelectedValueBPU(event.target.value);
  };

  useEffect(() => {
    if (selectedButtonBPJS === "Tenaga Kerja") {
      setSelectedValueBPU("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButtonBPJS]);

  return (
    <div className={Styles.container}>
      <h3>BPJS</h3>
      {
        optionalBPJS
        &&
        <div className={Styles.oCard}>
          <span>Gunakan skema BPJS untuk FLB ini</span>
          <Switch isChecked={withBPJS} setIsChecked={setWithBPJS} height="28px" width="56px" />
        </div>
      }
      {
        withBPJS
        &&
        <div className={Styles.content}>
          <div className={Styles.top}>
            <div className={Styles.selectWrapper}>
              <SelectOptionsToggle
                title="Pendaftaran BPJS"
                options={options?.registration}
                placeholder={"Select"}
                value={registration}
                setValue={setRegistration}
                isError={alertMessage && !registration}
                errorText="Pendaftaran BPJS belum dipilih"
                autoClose={true}
                isErrorV2={true}
                required
              />
            </div>
            <div className={Styles.selectWrapper}>
              <SelectOptionsToggle
                title="Bukti Pembayaran BPJS"
                options={options?.paymentProof}
                placeholder={"Select"}
                value={paymentProof}
                setValue={setPaymentProof}
                isError={alertMessage && !paymentProof}
                errorText="Bukti Pembayaran BPJS belum dipilih"
                autoClose={true}
                isErrorV2={true}
                required
              />
            </div>
            {/* <div className={Styles.selectWrapper}>
            <SelectOptionsToggle
              title="Potongan BPJS TKO"
              options={options?.cutOffBPJSTKO}
              placeholder={"Select"}
              value={cutOffBPJSTKO}
              setValue={setCutOffBPJSTKO}
              autoClose={true}
            />
          </div> */}
            <div className={Styles.cutOffSelector}>
              <InputTitle title={'Perhitungan Potongan BPJS TKO'} />
              <div className={Styles.cutOffOptions}>
                {options?.cutOffBPJSTKO?.map((c, cI) => (
                  <div key={cI} className={`${cutOffBPJSTKO === c?.name ? Styles.active : ''} ${alertMessage && !cutOffBPJSTKO ? Styles.error : ''}`} onClick={() => setCutOffBPJSTKO(c?.name)}>
                    {c?.name}
                  </div>
                ))}
              </div>
              {
                alertMessage && !cutOffBPJSTKO
                &&
                <ErrorBubble
                  errorText="Perhitungan potongan BPJS TKO belum dipilih"
                />
              }
            </div>
            {
              cutOffBPJSTKO === 'Manual'
              &&
              <div>
                <DinamicDecorativeInputField
                  onChange={(_, newVal) =>
                    setCutOffNominal(newVal)
                  }
                  name={''}
                  value={cutOffNominal || ""}
                  title={'Manual'}
                  adornment="Rp"
                  type="currency"
                  preventDelete={true}
                  isError={alertMessage && cutOffBPJSTKO === 'Manual' && !cutOffNominal}
                  errorText="Perhitungan Potongan BPJS TKO belum diisi"
                  isErrorV2={true}
                />
              </div>
            }
          </div>
          <div className={Styles.middle}>
            <div className={Styles.buttonOption}>
              <div
                className={`${Styles.buttonLeft} ${selectedButtonBPJS === "Tenaga Kerja" && Styles.buttonActive
                  }`}
                onClick={() => setActiveButtonBPJS("Tenaga Kerja")}
              >
                Penerima Upah
              </div>
              <div
                className={`${Styles.buttonRight} ${selectedButtonBPJS === "BPU" && Styles.buttonActive
                  }  ${jkk || jkm || jht || jp || form?.tkoContract?.includes('PKWT') ? Styles.disabled : ''}`}
                onClick={() => {
                  if (!jkk && !jkm && !jht && !jp && !form?.tkoContract?.includes('PKWT')) {
                    setActiveButtonBPJS("BPU");
                  }
                }}
              // style={{ cursor: !form?.tkoContract?.includes('PKWT') ? 'pointer' : 'auto' }}
              >
                Bukan Penerima Upah
              </div>
            </div>
            {selectedButtonBPJS === "Tenaga Kerja" && (
              <div>
                {" "}
                <h4>Ketenagarjaan</h4>
                <div className={Styles.subContent}>
                  <div className={Styles.selectSwitchWrapper}>
                    <SelectOptionsToggle
                      title="JKK (Jaminan Kelengkapan Kerja)"
                      options={options?.jkk}
                      placeholder={"Select"}
                      value={jkk}
                      setValue={setJkk}
                      withToggle={true}
                      toggleValue={jkkOn}
                      setToggleValue={setJkkOn}
                      autoClose={true}
                      isError={alertMessage && jkkOn && !jkk}
                      isErrorV2={true}
                      errorText="JKK belum dipilih"
                    />
                  </div>
                  <div className={Styles.selectSwitchWrapper}>
                    <SelectOptionsToggle
                      title="JKM (Jaminan Kematian)"
                      options={options?.jkm}
                      placeholder={"Select"}
                      value={jkm}
                      setValue={setJkm}
                      withToggle={true}
                      toggleValue={jkmOn}
                      setToggleValue={setJkmOn}
                      autoClose={true}
                      isError={alertMessage && jkmOn && !jkm}
                      isErrorV2={true}
                      errorText="JKM belum dipilih"
                    />
                  </div>
                  <div className={Styles.selectSwitchWrapper}>
                    <SelectOptionsToggle
                      title="JHT (Jaminan Hari Tua)"
                      options={options?.jht}
                      placeholder={"Select"}
                      value={jht}
                      setValue={setJht}
                      withToggle={true}
                      toggleValue={jhtOn}
                      setToggleValue={setJhtOn}
                      autoClose={true}
                      isError={alertMessage && jhtOn && !jht}
                      isErrorV2={true}
                      errorText="JHT belum dipilih"
                    />
                  </div>
                  <div className={Styles.selectSwitchWrapper}>
                    <SelectOptionsToggle
                      title="JP (Jaminan Pensiun)"
                      options={options?.jp}
                      placeholder={"Select"}
                      value={jp}
                      setValue={setJp}
                      withToggle={true}
                      toggleValue={jpOn}
                      setToggleValue={setJpOn}
                      autoClose={true}
                      isError={alertMessage && jpOn && !jp}
                      isErrorV2={true}
                      errorText="JP belum dipilih"
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedButtonBPJS === "BPU" && (
              <div className={Styles.bpuContent}>
                <span className={Styles.warnText}>
                  Silahkan pilih penagihan BPJS akan ditagih ke pihak perusahaan
                  atau karyawan. Jumlah perhitungan akan di input pada step ke-4
                  saat pengaturan gaji man power.
                </span>
                <div className={Styles.radioInputBox}>
                  <div className={Styles.radio}>
                    <input
                      type="radio"
                      value="Tagih Ke Perusahaan"
                      checked={selectedValueBPU === "Tagih Ke Perusahaan"}
                      onChange={handleRadioChange}
                    />
                    <label>Tagih Ke Perusahaan</label>
                  </div>
                  <div className={Styles.radio}>
                    <input
                      type="radio"
                      value="Tagih Ke Karyawan"
                      checked={selectedValueBPU === "Tagih Ke Karyawan"}
                      onChange={handleRadioChange}
                    />
                    <label>Tagih Ke Karyawan</label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={Styles.bottom}>
            <div className={Styles.boxWrapper}>
              <h4>Kesehatan</h4>
              <div className={Styles.selectSwitchWrapper}>
                <SelectOptionsToggle
                  title="KS (kesehatan)"
                  options={options?.health}
                  placeholder={"Select"}
                  value={ks}
                  setValue={setKs}
                  withToggle={true}
                  toggleValue={ksOn}
                  setToggleValue={setKsOn}
                  autoClose={true}
                  isError={alertMessage && ksOn && !ks}
                  isErrorV2={true}
                  errorText="KS belum dipilih"
                />
              </div>
            </div>
            <div className={Styles.boxWrapper}>
              <h4>AKDHK</h4>
              <div className={Styles.selectSwitchWrapper}>
                <SelectOptionsToggle
                  title="Asuransi Kecelakaan Diluar Hari Kerja"
                  options={options?.akdhk}
                  placeholder={"Select"}
                  value={akdhk}
                  setValue={setAkdhk}
                  toggleValue={akdhkOn}
                  setToggleValue={setAkdhkOn}
                  withToggle={true}
                  autoClose={true}
                  isError={alertMessage && akdhkOn && !akdhk}
                  isErrorV2={true}
                  errorText="AKDHK belum dipilih"
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
