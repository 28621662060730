/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";

const maxJP = 10042360;
const maxKS = 12000000;

const getSalaryInMonth = (periodStr, baseSalary) => {
  switch (periodStr) {
    case "Harian":
      return +baseSalary * 20;
    case "Mingguan":
      return +baseSalary * 4;
    default:
      return +baseSalary;
  }
};

export function CostEstimation() {
  const getTotalSalaryPerArea = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const salary = getSalaryInMonth(
          jobsArr[i]?.salaryPeriod,
          jobsArr[i]?.salary
        );
        const demand = jobsArr[i]?.demand;
        total += Number(salary) * Number(demand);
      }
      return total;
    },
    [getSalaryInMonth]
  );

  const getTotalUmkPerArea = (perLocationArr, umk) => {
    let total = 0;
    const jobsArr = perLocationArr?.jobs;
    for (let i = 0; i < jobsArr?.length; i++) {
      const demand = jobsArr[i]?.demand;
      total += Number(umk) * Number(demand);
    }

    return total;
  };

  const getBpjsNominal = useCallback(
    (salary, umk, cleanAllowance, fixedBpjsCut, bpjsPercentage) => {
      let nominal = 0;

      if (fixedBpjsCut?.includes("UMK")) {
        nominal = Math.ceil((bpjsPercentage * umk) / 100);
      } else if (fixedBpjsCut?.includes("+")) {
        nominal = Math.ceil(
          (bpjsPercentage * (Number(salary) + Number(cleanAllowance))) / 100
        );
      } else {
        nominal = Math.ceil((bpjsPercentage * salary) / 100);
      }
      return Math.ceil(nominal);
    },
    []
  );

  const getJPNominal = useCallback(
    (salary, umk, cleanAllowance, fixedBpjsCut, jpPercentage) => {
      let nominal = 0;

      if (fixedBpjsCut?.includes("UMK")) {
        const umkTotal = umk;
        nominal =
          +umkTotal > maxJP
            ? Math.ceil((jpPercentage * maxJP) / 100)
            : Math.ceil((jpPercentage * umkTotal) / 100);
      } else if (fixedBpjsCut?.includes("+")) {
        nominal =
          Number(salary) + Number(cleanAllowance) > maxJP
            ? Math.ceil((jpPercentage * maxJP) / 100)
            : Math.ceil(
                (jpPercentage * (Number(salary) + Number(cleanAllowance))) / 100
              );
      } else {
        nominal =
          salary > maxJP
            ? Math.ceil((jpPercentage * maxJP) / 100)
            : Math.ceil((jpPercentage * salary) / 100);
      }

      return Math.ceil(nominal);
    },
    [maxJP]
  );

  const getKSNominal = useCallback(
    (salary, umk, cleanAllowance, fixedBpjsCut, ksPercentage) => {
      let nominal = 0;

      if (fixedBpjsCut?.includes("UMK")) {
        const umkTotal = umk;
        nominal =
          +umkTotal > maxJP
            ? Math.ceil((ksPercentage * maxKS) / 100)
            : Math.ceil((ksPercentage * umkTotal) / 100);
      } else if (fixedBpjsCut?.includes("+")) {
        nominal =
          Number(salary) + Number(cleanAllowance) > maxKS
            ? Math.ceil((ksPercentage * maxKS) / 100)
            : Math.ceil(
                (ksPercentage * (Number(salary) + Number(cleanAllowance))) / 100
              );
      } else {
        nominal =
          salary > maxKS
            ? Math.ceil((ksPercentage * maxKS) / 100)
            : Math.ceil((ksPercentage * salary) / 100);
      }

      // console.log(Math.ceil(nominal), "getKSNominal");

      return Math.ceil(nominal);
    },
    [maxKS]
  );

  const getPersonalEquipmentsNominal = (equipmentsArr) => {
    let total = 0;

    for (let i = 0; i < equipmentsArr?.length; i++) {
      total += Number(equipmentsArr[i]?.value);
    }

    return total;
  };

  const getPersonalEquipmentsNominalPerArea = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const equipmentsArr = jobsArr[i]?.personalEquipments;
        const demand = jobsArr[i]?.demand;
        total +=
          Number(getPersonalEquipmentsNominal(equipmentsArr)) * Number(demand);
      }

      return total;
    },
    [getPersonalEquipmentsNominal]
  );

  const AllowanecesWithoutUAKandTHR = useCallback((arr) => {
    let total = 0;

    let arrFilter = arr?.filter(
      (arr) =>
        arr?.name?.toLowerCase() !== "uak" && arr?.name?.toLowerCase() !== "thr"
    );

    for (let a = 0; a < arrFilter?.length; a++) {
      const value = arrFilter[a]?.value;
      const denom = arrFilter[a]?.denom;
      const denomValue =
        denom?.toLowerCase() === "mingguan"
          ? 4
          : denom?.toLowerCase() === "harian"
          ? 20
          : 1;
      total += Number(value) * denomValue;
    }

    return total;
  }, []);

  const getUAK = useCallback((arr) => {
    let total = 0;

    let arrFilter = arr?.filter((arr) =>
      arr?.name?.toLowerCase()?.includes("uak")
    );

    for (let a = 0; a < arrFilter?.length; a++) {
      const value = arrFilter[a]?.value;
      const denom = arrFilter[a]?.denom;
      const denomValue =
        denom?.toLowerCase() === "mingguan"
          ? 4
          : denom?.toLowerCase() === "harian"
          ? 20
          : 1;
      total += Number(value) * denomValue;
    }

    return total;
  }, []);

  const getTHR = useCallback((arr) => {
    let total = 0;
    let arrFilter = arr?.filter((arr) =>
      arr?.name?.toLowerCase()?.includes("thr")
    );

    for (let a = 0; a < arrFilter?.length; a++) {
      const value = arrFilter[a]?.value;
      const denom = arrFilter[a]?.denom;
      const denomValue =
        denom?.toLowerCase() === "mingguan"
          ? 4
          : denom?.toLowerCase() === "harian"
          ? 20
          : 1;
      total += Number(value) * denomValue;
    }

    return total;
  }, []);

  const getAllowancesNominal = useCallback(
    (arr, showThr, showUak) => {
      let total = 0;

      if (!showThr && !showUak) {
        // console.log("hei");
        total = AllowanecesWithoutUAKandTHR(arr);
      } else if (!showThr) {
        total = AllowanecesWithoutUAKandTHR(arr) + getUAK(arr);
      } else if (!showUak) {
        total = AllowanecesWithoutUAKandTHR(arr) + getTHR(arr);
      } else {
        total = AllowanecesWithoutUAKandTHR(arr) + getTHR(arr) + getUAK(arr);
      }

      return total;
    },
    [AllowanecesWithoutUAKandTHR, getUAK, getTHR]
  );

  const getAllowancesBPUNominal = useCallback((arr) => {
    let total = 0;
    if (arr?.filter((el) => el?.type === "BPU")?.length > 0) {
      const filterArr = arr?.filter((el) => el?.type === "BPU");
      for (let i = 0; i < filterArr?.length; i++) {
        const name = filterArr[i]?.name;
        const value = filterArr[i]?.value;

        total += Number(filterArr[i]?.value);
      }
    }

    return total;
  }, []);

  const getAllowancesNominalWithBPU = useCallback(
    (arr, showThr, showUak) => {
      let total = 0;

      const filterArr = arr?.filter((el) => !el?.type);
      if (!showThr && !showUak) {
        total = AllowanecesWithoutUAKandTHR(filterArr);
      } else if (!showThr) {
        total = AllowanecesWithoutUAKandTHR(filterArr) + getUAK(filterArr);
      } else if (!showUak) {
        total = AllowanecesWithoutUAKandTHR(filterArr) + getTHR(filterArr);
      } else {
        total =
          AllowanecesWithoutUAKandTHR(filterArr) +
          getTHR(filterArr) +
          getUAK(filterArr);
      }

      return total;
    },
    [AllowanecesWithoutUAKandTHR, getUAK, getTHR]
  );

  const getAllowancesNominalWithoutTHR = useCallback(
    (arr) => {
      return AllowanecesWithoutUAKandTHR(arr);
    },
    [AllowanecesWithoutUAKandTHR]
  );

  const getAllowancesNominalPerAreaWithoutTHRWithoutBPU = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const allowanceArr = jobsArr[i]?.allowances?.filter(
          (obj) => obj?.type !== "BPU"
        );
        const demand = jobsArr[i]?.demand;
        total +=
          Number(getAllowancesNominalWithoutTHR(allowanceArr)) * Number(demand);
      }

      return total;
    },
    [getAllowancesNominalWithoutTHR]
  );

  const getAllowancesNominalWithoutTHRBPUOnly = useCallback((arr) => {
    let total = 0;

    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]?.name !== "THR" && arr[i]?.name !== "UAK") {
        total += Number(arr[i]?.value);
      }
    }

    return total;
  }, []);

  const getAllowancesNominalPerAreaBPUOnly = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const allowanceArr = jobsArr[i]?.allowances?.filter(
          (obj) => obj?.type === "BPU"
        );
        const demand = jobsArr[i]?.demand;
        total +=
          Number(getAllowancesNominalWithoutTHRBPUOnly(allowanceArr)) *
          Number(demand);
      }

      return total;
    },
    [getAllowancesNominalWithoutTHRBPUOnly]
  );

  const getTotalBpjsNominalPerArea = useCallback(
    (
      perLocationArr,
      umk,
      fixedBpjsCut,
      bpjsPercentage,
      jpPercentage,
      ksPercentage
    ) => {
      const totalSalary = getTotalSalaryPerArea(perLocationArr);
      const totalUmk = getTotalUmkPerArea(
        perLocationArr,
        umk || perLocationArr?.umk
      );
      const cleanAllowances =
        getAllowancesNominalPerAreaWithoutTHRWithoutBPU(perLocationArr);

      return (
        Number(
          getBpjsNominal(
            totalSalary,
            totalUmk,
            cleanAllowances,
            fixedBpjsCut,
            bpjsPercentage
          )
        ) +
        Number(
          getJPNominal(
            totalSalary,
            totalUmk,
            cleanAllowances,
            fixedBpjsCut,
            jpPercentage
          )
        ) +
        Number(
          getKSNominal(
            totalSalary,
            totalUmk,
            cleanAllowances,
            fixedBpjsCut,
            ksPercentage
          )
        )
      );
    },
    [
      getAllowancesNominalPerAreaWithoutTHRWithoutBPU,
      getBpjsNominal,
      getJPNominal,
      getKSNominal,
      getTotalSalaryPerArea,
      getTotalUmkPerArea,
    ]
  );

  const getEachManfeeGetter = useCallback(
    (
      salary,
      pEquipments,
      bpjs,
      allowances,
      thr,
      uak,
      fixedManfee,
      showUak,
      showThr
    ) => {
      let manfee = 0;

      if (fixedManfee?.includes("BPJS")) {
        manfee += Math.ceil(Number(bpjs));
      }
      if (fixedManfee?.includes("Gaji")) {
        manfee += Math.ceil(Number(salary));
      }
      if (fixedManfee?.includes("Equipment")) {
        manfee += Math.ceil(Number(pEquipments));
      }
      if (fixedManfee?.includes("Tunjangan")) {
        manfee += Math.ceil(Number(allowances));
      }

      if (showUak && fixedManfee?.includes("UAK")) {
        manfee += Math.ceil(Number(uak));
      }

      if (showThr && fixedManfee?.includes("THR")) {
        manfee += Math.ceil(Number(thr));
      }

      return manfee;
    },
    []
  );

  const getAllowancesNominalPerAreaTHRWithoutBPU = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const allowanceArr = jobsArr[i]?.allowances?.filter(
          (obj) => obj?.type !== "BPU"
        );
        const demand = jobsArr[i]?.demand;

        total += Number(getTHR(allowanceArr)) * Number(demand);
      }

      return total;
    },
    [getTHR]
  );

  const getAllowancesNominalPerAreaUAKWithoutBPU = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const allowanceArr = jobsArr[i]?.allowances?.filter(
          (obj) => obj?.type !== "BPU"
        );
        const demand = jobsArr[i]?.demand;

        total += Number(getUAK(allowanceArr)) * Number(demand);
      }

      return total;
    },
    [getUAK]
  );

  const getManfeeGetter = useCallback(
    (
      perLocationArr,
      umk,
      fixedManfee,
      selectedValueBPU,
      showUak,
      showThr,
      bpjsPercentage,
      jpPercentage,
      ksPercentage,
      fixedBpjsCut
    ) => {
      let manfee = 0;

      const totalSalary = getTotalSalaryPerArea(perLocationArr);
      const totalPersonalWorkEquipment =
        getPersonalEquipmentsNominalPerArea(perLocationArr);
      const totalBpjs =
        selectedValueBPU === "Tagih Ke Perusahaan"
          ? getAllowancesNominalPerAreaBPUOnly(perLocationArr)
          : Math.ceil(
              getTotalBpjsNominalPerArea(
                perLocationArr,
                umk,
                fixedBpjsCut,
                bpjsPercentage,
                jpPercentage,
                ksPercentage
              )
            );

      const totalAllowance =
        getAllowancesNominalPerAreaWithoutTHRWithoutBPU(perLocationArr);

      const totalUAK = getAllowancesNominalPerAreaUAKWithoutBPU(perLocationArr);
      const totalTHR = getAllowancesNominalPerAreaTHRWithoutBPU(perLocationArr);

      if (fixedManfee?.includes("BPJS")) {
        manfee += Math.ceil(Number(totalBpjs));
      }
      if (fixedManfee?.includes("Gaji")) {
        manfee += Math.ceil(Number(totalSalary));
      }
      if (fixedManfee?.includes("Equipment")) {
        manfee += Math.ceil(Number(totalPersonalWorkEquipment));
      }
      if (fixedManfee?.includes("Tunjangan")) {
        manfee += Math.ceil(Number(totalAllowance));
      }

      if (showUak && fixedManfee?.includes("UAK")) {
        manfee += Math.ceil(Number(totalUAK));
      }

      if (showThr && fixedManfee?.includes("THR")) {
        manfee += Math.ceil(Number(totalTHR));
      }

      // console.log(manfee, "test 22");

      return manfee;
    },
    [
      getTotalSalaryPerArea,
      getPersonalEquipmentsNominalPerArea,
      getAllowancesNominalPerAreaBPUOnly,
      getTotalBpjsNominalPerArea,
      getAllowancesNominalPerAreaWithoutTHRWithoutBPU,
      getAllowancesNominalPerAreaUAKWithoutBPU,
      getAllowancesNominalPerAreaTHRWithoutBPU,
    ]
  );

  const getAreaWorkEquipmentsNominal = (perLocationArr) => {
    let total = 0;

    const areaWorkEquipmentsArr = perLocationArr?.areaWorkEquipments;

    for (let i = 0; i < areaWorkEquipmentsArr?.length; i++) {
      total += Number(areaWorkEquipmentsArr[i]?.value);
    }

    return total;
  };

  const getTotalCostPerArea = useCallback(
    (
      perLocationArr,
      umk,
      noManfee = false,
      getSubTotalPerArea,
      manfee,
      manfeeMode,
      manfeePercentage,
      fixedManfee,
      selectedValueBPU,
      showUak,
      showThr,
      bpjsPercentage,
      jpPercentage,
      ksPercentage,
      fixedBpjsCut
    ) => {
      let total = 0;
      const subTotal = getSubTotalPerArea;
      const areaWorkEquipmentsNominal =
        getAreaWorkEquipmentsNominal(perLocationArr);
      const manfeePerArea =
        manfeeMode === "Rp"
          ? manfee
          : Math.ceil(
              (manfeePercentage *
                getManfeeGetter(
                  perLocationArr,
                  umk,
                  fixedManfee,
                  selectedValueBPU,
                  showUak,
                  showThr,
                  bpjsPercentage,
                  jpPercentage,
                  ksPercentage,
                  fixedBpjsCut
                )) /
                100
            );

      total +=
        Number(subTotal) +
        Number(areaWorkEquipmentsNominal) +
        (!noManfee ? Number(manfeePerArea) : 0);

      return total;
    },
    [getManfeeGetter]
  );

  return {
    getTotalSalaryPerArea,
    getTotalUmkPerArea,
    getBpjsNominal,
    getJPNominal,
    getKSNominal,
    getPersonalEquipmentsNominal,
    getPersonalEquipmentsNominalPerArea,
    AllowanecesWithoutUAKandTHR,
    getUAK,
    getTHR,
    getAllowancesNominal,
    getAllowancesBPUNominal,
    getAllowancesNominalWithBPU,
    getAllowancesNominalWithoutTHR,
    getAllowancesNominalPerAreaWithoutTHRWithoutBPU,
    getAllowancesNominalWithoutTHRBPUOnly,
    getAllowancesNominalPerAreaBPUOnly,
    getTotalBpjsNominalPerArea,
    getEachManfeeGetter,
    getAllowancesNominalPerAreaTHRWithoutBPU,
    getAllowancesNominalPerAreaUAKWithoutBPU,
    getManfeeGetter,
    getAreaWorkEquipmentsNominal,
    getTotalCostPerArea,
  };
}
