import Images from '@Theme/Images';
import { useEffect, useMemo, useRef, useState } from 'react';
import FifthSection from './Fifth';
import FirstSection from './First';
import FourthSection from './Fourth';
import SecondSection from './Second';
import Styles from './style.module.scss';
import ThirdSection from './Third';
import { useLocation } from 'react-router-dom';

export default function NewSixth({
  contract,
  contracts = [],
  onPrevious = () => { },
  onSubmit = () => { },
  isSubmitting,
  isSubmittingDraft,
  employmentType,
  workSchemeForm,
  additionalForm,
  contactInformation,
  remark,
  delegationsForm,
  deploymentsForm,
  positionsForm,
  cities,
  manfee,
  TOP = "",
  fixedManfee,
  fixedBpjsCut,
  selectedValueBPU,
  noTaxes = false
}) {

  const [currentStep, setCurrentStep] = useState(1)
  const {pathname} = useLocation()

  const main = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <FirstSection
          type={contract}
          documents={contracts}
        />
      case 2:
        return <SecondSection
          employmentType={employmentType}
          workSchemeForm={workSchemeForm}
          remark={remark}
        />
      case 3:
        return <ThirdSection
          additionalForm={additionalForm}
          contactInformation={contactInformation}
        />
      case 4:
        return <FourthSection
          delegationsForm={deploymentsForm}
          positionsForm={positionsForm}
        />
      case 5:
        return <FifthSection
          noTaxes={noTaxes}
          delegationsForm={delegationsForm}
          workSchemeForm={workSchemeForm}
          cities={cities}
          manfee={manfee}
          TOP={TOP}
          fixedManfee={fixedManfee}
          fixedBpjsCut={fixedBpjsCut}
          selectedValueBPU={selectedValueBPU}
          pph={workSchemeForm?.taxs?.pph23}
        />
      default:
        return <>no content</>
    }
  }, [TOP, additionalForm, cities, contactInformation, contract, contracts, currentStep, delegationsForm, deploymentsForm, employmentType, fixedBpjsCut, fixedManfee, manfee, noTaxes, positionsForm, remark, selectedValueBPU, workSchemeForm])

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.content}>
        <div className={Styles.steps}>
          {
            [
              {
                id: 1,
                label: "Contract Type",
              },
              {
                id: 2,
                label: "Work Scheme",
              },
              {
                id: 3,
                label: "Additional Information",
              },
              {
                id: 4,
                label: "Position",
              },
              {
                id: 5,
                label: "Payment Method & Estimation",
              },
            ]
              ?.map((s, sI) => (
                <div
                  key={sI}
                  className={`${Styles[currentStep === s?.id ? 'active' : '']}`}
                  onClick={() => setCurrentStep(s?.id)}
                >
                  {s?.label}
                </div>
              ))
          }
        </div>
        <div className={Styles.info}>
          <img src={Images.CHECK_LIST} alt='' />
          <div className={Styles.desc}>
            <span>Summary</span>
            <p>Periksa kembali dan pastikan Anda telah memasukan data dengan benar, kemudian klik submit untuk mengirim FLB untuk di proses</p>
          </div>
          <div className={Styles.actions}>
            {
              !pathname?.includes('edit')
              &&
              <button
              className={Styles.saveToDraft}
              onClick={(e) => onSubmit(e, 'draft')}
              disabled={isSubmittingDraft || isSubmitting
              }
            >{isSubmittingDraft ? 'Saving as Draft...' : 'Save as Draft'}</button>
            }
            <button
              disabled={
                isSubmittingDraft ||
                isSubmitting
              }
              onClick={(e) => onSubmit(e, "send")}
              className={Styles.submit}
            >
              {isSubmitting ? "Sending..." : "Send as FLB"}
            </button>
          </div>
        </div>
        <div className={Styles.main}>
          {
            main
          }
        </div>
      </div>

      {/* <div className={Styles.buttonsWrapper}>
        <div className={Styles.left}>
          <button onClick={onPrevious} className={Styles.prev}>
            Back
          </button>
        </div>
        <div className={Styles.right}>
          <button
            className={Styles.saveToDraft}
            onClick={(e) => onSubmit(e, 'draft')}
            disabled={isSubmittingDraft || isSubmitting
            }
          >{isSubmittingDraft ? 'Saving as Draft...' : 'Save as Draft'}</button>
          <button
            disabled={
              isSubmittingDraft ||
              isSubmitting
            }
            onClick={(e) => onSubmit(e, "send")}
            className={Styles.submit}
          >
            {isSubmitting ? "Sending..." : "Send FLB"}
          </button>
        </div>
      </div> */}
    </div>
  )
}