import { useMemo } from "react";
import Styles from "./style.module.scss";
import { rupiahFormat } from "@Helpers/formatRupiah";

export default function TaxAndBPJSSection({
  data,
  selectedLogs,
  isHC,
  updateLogs,
}) {
  const orderComprehensive = useMemo(() => {
    return data?.orderDetail?.orderComprehensives;
  }, [data]);

  const noBPJS = useMemo(() => {
    return (
      (!orderComprehensive?.PendaftaranBPJS || orderComprehensive?.PendaftaranBPJS === 'null')
      &&
      (!orderComprehensive?.BuktiPendaftaranBPJS || orderComprehensive?.BuktiPendaftaranBPJS === 'null')
      &&
      (!orderComprehensive?.PotonganBPJSTKO || orderComprehensive?.PotonganBPJSTKO === 'null')
    )
  }, [orderComprehensive?.BuktiPendaftaranBPJS, orderComprehensive?.PendaftaranBPJS, orderComprehensive?.PotonganBPJSTKO])

  return (
    <div className={Styles.container} id="Taxs">
      <div className={Styles.header}>
        <h3>Tax & BPJS</h3>
      </div>
      <div className={Styles.content}>
        <div
          className={`${Styles.row} ${isHC && selectedLogs?.remark?.includes("Tax") && Styles.isActive
            } ${isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.filter((log) => log?.remark?.includes("Tax"))?.length >
            0 &&
            Styles.borderYellow
            }`}
        >
          <div className={Styles.col}>
            <h4>Taxs</h4>
          </div>
          <div
            className={`${Styles.col} ${Styles.miniPadding} ${Styles.eachFifty}`}
          >
            <div className={Styles.stacked}>
              <span>PPH21</span>
              <span>{orderComprehensive?.PPH21}</span>
            </div>
            <div className={Styles.stacked}>
              <span>PPH23</span>
              <span>{orderComprehensive?.PPH23 || "-"}</span>
            </div>
            <div className={Styles.stacked}>
              <span>PPN</span>
              <span>{orderComprehensive?.PPN}</span>
            </div>
          </div>
        </div>
        <div
          className={`${Styles.row} ${isHC && selectedLogs?.remark?.includes("BPJS") && Styles.isActive
            } ${isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.filter((log) => log?.remark?.includes("BPJS"))?.length >
            0 &&
            Styles.borderYellow
            }`}
          id="BPJS"
        >
          <div className={Styles.col}>
            <h4>BPJS</h4>
          </div>
          {
            noBPJS
              ?
              <div className={`${Styles.col} ${Styles.normalPadding}`}>
                <span>
                  FLB ini tidak menggunakan skema BPJS
                </span>
              </div>
              :
              <div className={`${Styles.col} ${Styles.miniPadding}`}>
                <div className={Styles.colSection}>
                  <div className={Styles.col}>
                    <div className={Styles.stacked}>
                      <span>Pendaftaran BPJS</span>
                      <span>{orderComprehensive?.PendaftaranBPJS}</span>
                    </div>
                    <div className={Styles.stacked}>
                      <span>Bukti pembayaran BPJS</span>
                      <span>{orderComprehensive?.BuktiPendaftaranBPJS}</span>
                    </div>
                  </div>

                  <div className={Styles.col}>
                    <div className={Styles.stacked}>
                      <span>Perhitungan BPJS TKO</span>
                      <span>
                        {!isNaN(Number(orderComprehensive?.PotonganBPJSTKO))
                          ? "Manual"
                          : orderComprehensive?.PotonganBPJSTKO}
                      </span>
                    </div>
                    {!isNaN(Number(orderComprehensive?.PotonganBPJSTKO)) ? (
                      <div className={Styles.stacked}>
                        <span>Angka Perhitungan</span>
                        <span>
                          {rupiahFormat(orderComprehensive?.PotonganBPJSTKO)}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <h5 className={Styles.ExtraPadding}>
                    {orderComprehensive?.PenagihanBPU === "" ||
                      orderComprehensive?.PenagihanBPU === null
                      ? "Penerima Upah"
                      : "Bukan Penerima Upah (BPU)"}
                  </h5>
                  <div className={Styles.stacked}>
                    <span>Ditanggung oleh</span>
                    <span>
                      {orderComprehensive?.PenagihanBPU?.includes("Perusahaan")
                        ? "Perusahaan"
                        : "Karyawan"}
                    </span>
                  </div>
                  {orderComprehensive?.PenagihanBPU === "" ||
                    orderComprehensive?.PenagihanBPU === null ? (
                    <div>
                      {" "}
                      <div className={Styles.col}>
                        <div className={Styles.stacked}>
                          <span>JKK (Jaminan Kelengkapan Kerja)</span>
                          <span>
                            {orderComprehensive?.JKK &&
                              orderComprehensive?.JKK !== "null"
                              ? orderComprehensive?.JKK
                              : "-"}
                          </span>
                        </div>
                        <div className={Styles.stacked}>
                          <span>JHT (Jaminan Hari Tua)</span>
                          <span>
                            {orderComprehensive?.JHT &&
                              orderComprehensive?.JHT !== "null"
                              ? orderComprehensive?.JHT
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className={Styles.col}>
                        <div className={Styles.stacked}>
                          <span>JKM (Jaminan Kematian)</span>
                          <span>
                            {orderComprehensive?.JKM &&
                              orderComprehensive?.JKM !== "null"
                              ? orderComprehensive?.JKM
                              : "-"}
                          </span>
                        </div>
                        <div className={Styles.stacked}>
                          <span>JP (Jaminan Pensiun)</span>
                          <span>
                            {orderComprehensive?.JP &&
                              orderComprehensive?.JP !== "null"
                              ? orderComprehensive?.JP
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={Styles.col}>
                      <div className={Styles.stacked}>
                        <span>Penagihan BPJS</span>
                        <span>
                          {orderComprehensive?.PenagihanBPU ===
                            "Tagih Ke Perusahaan"
                            ? "Penagihan BPJS akan ditagihkan kepada perusahaan"
                            : "Penagihan BPJS akan ditagihkan kepada karyawan"}
                        </span>
                      </div>
                    </div>
                  )}

                  <h5 className={Styles.ExtraPadding}>Kesehatan</h5>
                  <div className={Styles.stacked}>
                    <span>KS (Kesehatan)</span>
                    <span>
                      {orderComprehensive?.KS && orderComprehensive?.KS !== "null"
                        ? orderComprehensive?.KS
                        : "-"}
                    </span>
                  </div>
                  <h5 className={Styles.ExtraPadding}>AKDHK</h5>
                  <div className={Styles.stacked} id="Manfee">
                    <span>Asuransi Kecelakaan Diluar Hari Kerja</span>
                    <span>
                      {orderComprehensive?.AKDHK &&
                        orderComprehensive?.AKDHK !== "null"
                        ? orderComprehensive?.AKDHK
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
          }

        </div>
        <div
          className={`${Styles.row} ${isHC &&
            selectedLogs?.remark === "Perubahan Penagihan Manfee" &&
            Styles.isActive
            } ${isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Penagihan Manfee"
            ) &&
            Styles.borderYellow
            }`}
        >
          <div className={Styles.col}>
            <h4>Pengenaan Manfee</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {orderComprehensive?.PengenaanManfee?.replace(/::/g, ", ")}
            </span>
          </div>
        </div>
        <div
          className={`${Styles.row} ${isHC &&
            selectedLogs?.remark === "Perubahan Penagihan THR" &&
            Styles.isActive
            } ${isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Penagihan THR"
            ) &&
            Styles.borderYellow
            }`}
        >
          <div className={Styles.col}>
            <h4>Penagihan THR</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{orderComprehensive?.PenagihanTHR}</span>
          </div>
        </div>
        <div
          className={`${Styles.row} ${isHC &&
            selectedLogs?.remark === "Perubahan Penagihan UAK" &&
            Styles.isActive
            } ${isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Penagihan UAK"
            ) &&
            Styles.borderYellow
            }`}
        >
          <div className={Styles.col}>
            <h4>Penagihan UAK</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{orderComprehensive?.PenagihanUAK}</span>
          </div>
        </div>
        <div
          className={`${Styles.row} ${isHC &&
            selectedLogs?.remark === "Perubahan Rekening Wajib TKO" &&
            Styles.isActive
            } ${isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Rekening Wajib TKO"
            ) &&
            Styles.borderYellow
            }`}
        >
          <div className={Styles.col}>
            <h4>Rekening Wajib TKO</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {orderComprehensive?.RekeningWajibTKO?.replace(/::/g, ", ")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
