import LoadingAnimation from "@Atom/LoadingAnimation";
import { useDebounce } from "@Hooks/useDebounce";
import FLBVerificationListLayout from "@Organism/HumanCapital/FLBVerification/FLBVerificationList";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getFLBListVerif } from "@Services/hc/flbVerification";
import { Suspense, useEffect, useState } from "react";
import {
  Await,
  defer,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";

export async function loader(route) {
  // const url = new URL(route?.request?.url);
  // const limit = url.searchParams.get("l") || 10;
  // const page = url.searchParams.get("p") || 1;
  // const search = url.searchParams.get("n") || "";
  // const verified = url.searchParams.get("v") || "no";

  return defer({
    packageFlbList: getFLBListVerif(10, 1, "Waiting", "", "", ""),
  });
}

export default function FLBVerificationListPage() {
  const { packageFlbList } = useLoaderData();
  const defaultLimit = 10;
  const defaultPage = 1;
  const defaultSearch = "";

  const [limit, setLimit] = useState(defaultLimit);
  const [page, setPage] = useState(defaultPage);
  const [search, setSearch] = useState(defaultSearch);
  const [verify, setVerify] = useState("no");
  const [isFecthingData, setIsFecthingData] = useState(false);

  const navigate = useNavigate();

  const debouncedSearch = useDebounce(search, 300);

  const { pathname } = useLocation();

  useEffect(() => {
    navigate(`${pathname}?v=${verify}`);
  }, [navigate, pathname, verify]);

  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "99vh",
            // placeItems: 'center'
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingAnimation />
        </div>
      }
    >
      <Await
        resolve={packageFlbList}
        errorElement={
          <div
            style={{
              height: "calc(100vh - 150px)",
              display: "grid",
              placeItems: "center",
            }}
          >
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageFlbList) => (
          <FLBVerificationListLayout
            initialData={packageFlbList?.response}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            search={search}
            setSearch={setSearch}
            verify={verify}
            setVerify={setVerify}
            isFecthingData={isFecthingData}
            setIsFecthingData={setIsFecthingData}
            debouncedSearch={debouncedSearch}
          />
        )}
      </Await>
    </Suspense>
  );
}
