import SchemeEditFormLayout from "@Organism/Manager/Client/WorkSchemes/SchemeEditForm/Layout";
import SchemeEditFormNavigation from "@Organism/Manager/Client/WorkSchemes/SchemeEditForm/Navigation";
import {
  getAllContactsByClientCode,
  orderSync,
} from "@Services/manager/client";
import { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
import LoadingSkeleton from "../../AddNewOrder/Skeleton";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getPipelineOrderDetails } from "@Services/manager/pipeline";
import Icon from "@Atom/Icon";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const id = params?.get("i");
  const code = params.get("c") || "";
  // const step = params.get("st") || "";

  const packageOrderSyncPromise = orderSync();
  const packagePipelineOrderDetailPromise = getPipelineOrderDetails(id);
  const packageClientContactPromise = getAllContactsByClientCode(code);

  return defer({
    packageOrderSync: packageOrderSyncPromise,
    packageOrderDetails: packagePipelineOrderDetailPromise,
    packageClientContacts: packageClientContactPromise,
  });
}

export default function SchemeEditForm() {
  const { packageOrderSync, packageOrderDetails, packageClientContacts } =
    useLoaderData();
  const [alertMessage, setAlertMessage] = useState("");
  const [contractType, setContractType] = useState(null);
  const defaultStep = sessionStorage.getItem("orderStepEdit");
  const [currentStep, setCurrentStep] = useState(
    defaultStep ? +defaultStep : 1
  );
  const [stepsOptions, setStepsOptions] = useState([
    {
      id: 1,
      label: "Contract Type",
      isDone: true,
    },
    {
      id: 2,
      label: "Work Scheme",
      isDone: true,
      // warning: true,
    },
    {
      id: 3,
      label: "Additional Information",
      isDone: true,
      // warning: true,
    },
    {
      id: 4,
      label: "Deployment",
      isDone: true,
    },
    {
      id: 5,
      label: "Position",
      isDone: true,
    },
    {
      id: 6,
      label: "Payment Method & Estimation",
      isDone: true,
    },
    {
      id: 7,
      label: "Summary",
      isDone: true,
    },
    // {
    //   id: 6,
    //   label: "Deployment",
    //   isDone: false,
    //   warning: true,
    // },
    // {
    //   id: 7,
    //   label: "Finish",
    //   isDone: false,
    //   warning: false,
    // },
  ]);

  const handleClickStep = (step) => {
    if (currentStep > step) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("orderStepEdit", currentStep);
  }, [currentStep]);

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef();

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Scheme Edit Form</title>
      </Helmet>
      <div ref={headerRef} className={Styles.headerWrapper}>
        <div className={Styles.navigationWrapper}>
          <SchemeEditFormNavigation
            stepsOptions={stepsOptions}
            currentStep={currentStep}
            contractType={contractType}
            handleClickStep={handleClickStep}
          />
        </div>
        {alertMessage ? (
          <div className={Styles.infoWrapper}>
            <Icon icon={"alert-solid"} size={20} color={"#F5610D"} />
            <span>{alertMessage}</span>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={Styles.layoutWrapper}>
        <Suspense fallback={<LoadingSkeleton />}>
          <Await
            resolve={packageOrderSync}
            errorElement={
              <div className={Styles.errorWrapper}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageOrderSync) => (
              <Suspense fallback={<LoadingSkeleton />}>
                <Await
                  resolve={packageOrderDetails}
                  errorElement={
                    <div className={Styles.errorWrapper}>
                      <AsyncErrorBoundary />
                    </div>
                  }
                >
                  {(packageOrderDetails) => (
                    <Suspense fallback={<LoadingSkeleton />}>
                      <Await resolve={packageClientContacts}>
                        {(packageClientContacts) => (
                          <SchemeEditFormLayout
                            syncData={packageOrderSync?.response}
                            defaultOrderForm={packageOrderDetails?.response}
                            steps={stepsOptions}
                            setSteps={setStepsOptions}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            contractType={contractType}
                            setContractType={setContractType}
                            clientContacts={packageClientContacts?.response}
                            alertMessage={alertMessage}
                            setAlertMessage={setAlertMessage}
                            headerHeight={headerHeight}
                            previousButtonText={
                              currentStep !== 1
                                ? stepsOptions[+currentStep - 2]?.label
                                : ""
                            }
                            nextButtonText={
                              currentStep !== 7
                                ? stepsOptions[+currentStep]?.label
                                : ""
                            }
                          />
                        )}
                      </Await>
                    </Suspense>
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
