/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import Styles from "./searchBar.module.scss";
import { useEffect, useRef, useState } from "react";
import UseOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";
import { Tooltip } from "@mui/material";
import { fileBaseUrl } from "@Config/api";

const SearchBar = ({
  placeholder,
  value,
  onChange,
  type,
  isRecruitment,
  options,
  isLoadingFilter,
  onClickOption,
  setValueIdCandidate,
  valueIdCandidate,
  isBS = false,
  isWhite,

  ...props
}) => {
  const [expandOptions, setExpandOptions] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");
  const boxRef = useRef();

  const maxLength = 7;
  const maxLengthName = 18;
  // console.log(acceptedFiles.includes("image"));

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expandOptions) {
      setExpandOptions(false);
    }
  }, [boxOutsideClick, expandOptions]);

  if (isRecruitment) {
    return (
      <div className={Styles.boxDropdown} ref={boxRef}>
        <div className={Styles.container}>
          <div className={Styles.inputWrapper}>
            <input
              className={`${Styles.input} ${
                type === "none" && Styles.inputNone
              }`}
              type={type === "none" ? "text" : "text"}
              id="fname"
              name="fname"
              onChange={(e) => {
                setExpandOptions(true);
                onChange(e?.target?.value || "");
              }}
              placeholder={placeholder}
              value={value || ""}
            />

            {value && (
              <button
                type="button"
                className={`${Styles.clearButton} ${
                  !isBS &&
                  valueIdCandidate?.ExpectedPosition?.name &&
                  Styles.clearButtonPosition
                } ${isBS && Styles.clearButtonBS}`}
              >
                {!isBS && valueIdCandidate?.ExpectedPosition?.name ? (
                  <Tooltip
                    title={
                      <span>{valueIdCandidate?.ExpectedPosition?.name}</span>
                    }
                  >
                    <span>
                      {" "}
                      {valueIdCandidate?.ExpectedPosition?.name
                        ? valueIdCandidate?.ExpectedPosition?.name?.length >
                          maxLength
                          ? valueIdCandidate?.ExpectedPosition?.name?.substring(
                              0,
                              maxLength
                            ) + "..."
                          : valueIdCandidate?.ExpectedPosition?.name
                        : "-"}
                    </span>
                  </Tooltip>
                ) : null}
                <Icon
                  icon={"cross"}
                  size={15}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange("");
                    setSelectedCode("");
                    setValueIdCandidate(null);
                  }}
                />
              </button>
            )}
          </div>
          <div
            className={`${Styles.searchWrapper} ${
              type === "none" && Styles.searchNone
            }`}
          >
            <Icon
              icon="search"
              className={`${Styles.searchIcon} ${
                type === "none" && Styles.searchIconNone
              } ${isBS && Styles.searchIconBS}`}
              size={"20px"}
            />
          </div>
        </div>

        {expandOptions && value && (
          <div
            className={`${Styles.optionsWrapper} ${
              isBS && Styles.optionsWrapperBS
            }`}
          >
            {isLoadingFilter && (
              <span className={Styles.loading}>Loading...</span>
            )}
            {options?.length > 0 && !isLoadingFilter && (
              <div className={Styles.optionSelect}>
                {options?.map((el, idx) => {
                  return (
                    <div
                      className={`${Styles.options} ${
                        idx === options?.length - 1 && Styles.noBorder
                      }`}
                      key={idx}
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        setExpandOptions(false);
                        // onClickOption(obj);
                        onClickOption(
                          el?.status,
                          el?.stage,
                          el?.tag,
                          el?.portal,
                          el,
                          el?.name
                        );
                        onChange(el?.name);
                        setSelectedCode(el?.ExpectedPosition?.name);
                        setValueIdCandidate(el);
                      }}
                    >
                      <div className={Styles.left}>
                        <div>
                          <img
                            src={
                              el?.CandidateDocuments
                                ? fileBaseUrl + el?.CandidateDocuments?.url
                                : Images.AVA_DEFAULT
                            }
                            alt=""
                          />
                        </div>

                        <div>
                          <span>{el?.name || "-"}</span>
                          <span>{el?.personId || "-"}</span>
                        </div>
                      </div>

                      <div className={Styles.right}>
                        <span>{el?.ExpectedPosition?.name || "-"}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {options?.length === 0 && !isLoadingFilter && (
              <div className={Styles.noFound}>Data tidak ditemukan</div>
            )}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.inputWrapper}>
        <input
          className={`${Styles.input} ${
            type === "none" || isWhite ? Styles.inputNone : ""
          }`}
          type={type === "none" || isWhite ? "text" : "search"}
          id="fname"
          name="fname"
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={value}
        />
      </div>
      <div
        className={`${Styles.searchWrapper} ${
          type === "none" || isWhite ? Styles.searchNone : ""
        }`}
      >
        <Icon
          icon="search"
          className={`${Styles.searchIcon} ${
            type === "none" && Styles.searchIconNone
          } ${isWhite && Styles.searchIconBS}`}
          size={"20px"}
        />
      </div>
    </div>
  );
};

export default SearchBar;
